import Icon from "components/atoms/icon/Icon";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IInfoCardProps {
  title?: string;
  icon?: IconType | any;
  count?: string;
  updatedate?: string;
  percentageup?: IconType | any;
  percentagedown?: IconType;
  percentage?: string;
  color: string;
  infoBox?: string;
}

const InfoCard: FC<IInfoCardProps> = ({
  title,
  icon,
  count,
  updatedate,
  percentageup,
  percentagedown,
  percentage,
  color,
  infoBox,
}) => {
  const { themeColors } = useThemeContext();
  const { t } = useTranslation();
  return (
    <div title={infoBox} className="w-full bg-white h-auto rounded-[20px] border-[#F2F4F7] border-solid border">
      <div className="p-5 pb-0 flex items-center gap-2">
        <div
          style={{
            backgroundColor: `${themeColors.primary}0D`,
          }}
          className={`w-[40px] h-[40px] flex items-center justify-center rounded-[5px]`}
        >
          <Icon iconType={icon} color={color} />

        </div>
        <h1 className="text-primaryTextColor text-[14px] font-inter-medium">
          {title}
        </h1>
      </div>
      <div className="py-[10px] px-5 flex items-center justify-between">
        {
          (count && typeof count === 'string' && count?.includes('CHF')) ? (
            <h1 className="text-primaryTextColor text-[30px] font-inter-medium">
              {count?.split(' ')[0]} <span className="text-[20px]">CHF</span>
            </h1>
          )
            : (
              <h1 className="text-primaryTextColor text-[30px] font-inter-medium">
                {count}
              </h1>
            )
        }
        {/* <div
          className={`p-2 rounded-[5px] bg-opacity-10 flex items-center gap-2  ${percentageup
            ? "bg-[#2CC95B] text-[#2CC95B]"
            : "bg-[#F45B69] text-[#F45B69]"
            }`}
        >
          <Icon iconType={percentageup || percentagedown} /> <span className="text-[11px] font-inter-medium">{percentage} $</span>
        </div> */}
      </div>
      <p className="border-t-[1px] p-2 pl-5 border-[#A2A1A8] opacity-30 opacity-border-10 text[#A2A1A8] text-[12px] font-inter-regular">
        {t('Update')}: {updatedate}
      </p>
    </div>
  );
};

export default InfoCard;
