import { getCustomerSource } from "api/Customers";
import { useQuery } from "react-query";

interface DocumentParams {
    costumer_id?: any;
}

export const useCustomerSource = (
    params: DocumentParams,
) => {
    const queryInfo = useQuery(
        ['customerSource'],
        () => getCustomerSource(params),
        {
            onError: (err) => {
                console.error(err);
            },

        }
    );

    return queryInfo;
};