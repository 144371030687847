import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewSuccessCompanyModal from './modals/AddNewSuccessCompanyModal'
import AddNewSuccessModal from './modals/AddNewSuccessModal'
import { useMonthlySuccess } from 'lib/hooks/queries/Finances/useMonthlySuccess'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import CreatableSelect from 'react-select/creatable'
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles'
import { months } from 'lib/helpers/constants/months'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import { usePermissions } from 'lib/hooks/shared/usePermissions'

const FinancesSuccessView = () => {
  const { t } = useTranslation()

  const hasPermissionToCreateSuccess = usePermissions(['performance_tracking_create']);

  const [FilterData, setFilterData] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

  const [IsSuccessModalOpen, setIsSuccessModalOpen] = useState<undefined | string>(undefined)
  const [IsSuccessCompanyModalOpen, setIsSuccessCompanyModalOpen] = useState<undefined | { section_id: string, section_name: string }>(undefined)

  const { data, isLoading } = useMonthlySuccess(FilterData)

  const [UpdateSuccessCompanyModalData, setUpdateSuccessCompanyModalData] = useState<any>()

  const [UpdateSuccessModalData, setUpdateSuccessModalData] = useState<any>()

  const [ModalStep, setModalStep] = useState(1)


  if (isLoading) {
    return <LoadingScreen />
  }
  return (
    <div>
      <div className='flex gap-4 items-center mt-[46px] mb-[33px]'>
        <Headline title={t("Monthly Success")} className='!mb-0 !mt-0 flex-1' />

        <CreatableSelect<any, false>

          options={[
            { value: 2023, label: "2023" },
            { value: 2024, label: "2024" },
            { value: 2025, label: "2025" },
            { value: 2026, label: "2026" },
          ]}
          value={
            FilterData.year
              ? {
                value: FilterData.year,
                label: FilterData.year,
              }
              : null
          }
          className="cursor-pointer font-inter-regular react-select-container"
          classNamePrefix="react-select"
          onChange={(selectedOption) => {
            const selectedValue = selectedOption
              ? selectedOption.value
              : null;
            setFilterData({ ...FilterData, year: selectedValue });
          }}
          styles={customStylesLightBorder}
          isValidNewOption={() => false}
          menuPosition={"fixed"}
          menuPortalTarget={document.body}
        />

        <CreatableSelect<any, false>
          options={
            months.map((month) => ({
              value: month.value,
              label: t(month.label),
            }))
          }
          value={
            FilterData.month
              ? {
                value: FilterData.month,
                label: months[FilterData.month - 1].label,
              }
              : null
          }
          className="cursor-pointer font-inter-regular react-select-container"
          classNamePrefix="react-select"
          onChange={(selectedOption) => {
            const selectedValue = selectedOption
              ? selectedOption.value
              : null;
            setFilterData({ ...FilterData, month: selectedValue });
          }}
          styles={customStylesLightBorder}
          isValidNewOption={() => false}
          menuPosition={"fixed"}
          menuPortalTarget={document.body}
        />
      </div>

      <div className='flex gap-3'>
        <div className='flex-1'>
          <DashboardBoxLayout>
            <DashboardBoxTitle title='Lead' className='pb-3 !text-[--theme]' />
            <div className='flex flex-col gap-3'>
              {
                (data as any).filter((section: any) => section.head_section === 'Lead')?.map((section: any) => {
                  return (
                    <DashboardBoxLayout className='h-[300px] ' key={section.id}>
                      <div className='min-h-[90px] pb-[5px]'>
                        <div className='flex gap-3 items-center justify-between'>
                          <div className='cursor-pointer' onClick={() => { setUpdateSuccessModalData({ section_id: section.id, name: section.section }) }}>
                            <span className='text-[20px] font-inter-medium text-primaryTextColor'>{t(section.section)}</span>
                          </div>
                          <ConditionalRender condition={hasPermissionToCreateSuccess}>
                          <div onClick={() => { setIsSuccessCompanyModalOpen({ section_id: section.id, section_name: section.section }) }}
                            className='flex-none h-[36px] w-[36px] rounded-[8px] bg-[--theme] grid place-items-center cursor-pointer'>
                            <Icon iconType={IconType.PLUS_ICON} />
                          </div>
                          </ConditionalRender>
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 verticalOverflowScrollStyle h-[165px]'>
                        {
                          (section as any).data?.map((data: any) => {
                            return (

                              <div
                                className='flex items-center justify-between font-inter-regular gap-2 cursor-pointer'
                                onClick={() => { setUpdateSuccessCompanyModalData({ section_id: section.id, section_name: section.section, data: data }) }}
                              >
                                <div className='h-[30px] w-[30px] flex-none'>
                                  <img
                                    src={'https://devmainhub.insusales.com/api/partners/logo/' + data.partner_id.logo}
                                    alt=""
                                    className='h-full w-full object-contain rounded-full'
                                  />
                                </div>
                                <span className='flex-1'>
                                  {data.partner_id.name}
                                </span>
                                <span className='font-inter-semibold text-[--theme]'>
                                  {data.amount} CHF
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </DashboardBoxLayout>
                  )
                })
              }

              <ConditionalRender condition={hasPermissionToCreateSuccess}>
              <DashboardBoxLayout className='border-dashed border-2 !border-[#D8D8D8] cursor-pointer'>
                <div className='h-[260px] grid place-items-center' onClick={() => { setIsSuccessModalOpen('Lead') }}>
                  <div className='flex flex-col gap-3 items-center'>
                    <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                    <div>
                      <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>
                    </div>
                  </div>
                </div>
              </DashboardBoxLayout>
              </ConditionalRender>
            </div>
          </DashboardBoxLayout>
        </div>

        <div className='flex-1'>
          <DashboardBoxLayout>
            <DashboardBoxTitle title='Appointment' className='pb-3 !text-[--theme]' />
            <div className="flex flex-col gap-3">
              {
                (data as any).filter((section: any) => section.head_section === 'Appointment')?.map((section: any) => {
                  return (
                    <DashboardBoxLayout className='h-[300px] ' key={section.id}>
                      <div className='min-h-[90px] pb-[5px]'>
                        <div className='flex gap-3 items-center justify-between'>
                          <div className='cursor-pointer' onClick={() => { setUpdateSuccessModalData({ section_id: section.id, name: section.section }) }}>
                            <span className='text-[20px] font-inter-medium text-primaryTextColor'>{t(section.section)}</span>
                          </div>
                          <ConditionalRender condition={hasPermissionToCreateSuccess}>
                          <div onClick={() => { setIsSuccessCompanyModalOpen({ section_id: section.id, section_name: section.section }) }}
                            className='flex-none h-[36px] w-[36px] rounded-[8px] bg-[--theme] grid place-items-center cursor-pointer'>
                            <Icon iconType={IconType.PLUS_ICON} />
                          </div>
                          </ConditionalRender>
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 verticalOverflowScrollStyle h-[165px]'>
                        {
                          (section as any).data?.map((data: any) => {
                            return (

                              <div className='flex items-center justify-between font-inter-regular gap-2 cursor-pointer'
                                onClick={() => { setUpdateSuccessCompanyModalData({ section_id: section.id, section_name: section.section, data: data }) }}>

                                <div className='h-[30px] w-[30px] flex-none'>
                                  <img
                                    src={'https://devmainhub.insusales.com/api/partners/logo/' + data.partner_id.logo}
                                    alt=""
                                    className='h-full w-full object-contain rounded-full'
                                  />
                                </div>
                                <span className='flex-1'>
                                  {data.partner_id.name}
                                </span>
                                <span className='font-inter-semibold text-[--theme]'>
                                  {data.amount} CHF
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </DashboardBoxLayout>
                  )
                })
              }
              <ConditionalRender condition={hasPermissionToCreateSuccess}>
              <DashboardBoxLayout className='border-dashed border-2 !border-[#D8D8D8] cursor-pointer'>
                <div className='h-[260px] grid place-items-center' onClick={() => { setIsSuccessModalOpen('Appointment') }}>

                  <div className='flex flex-col gap-3 items-center'>
                    <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                    <div>
                      <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>

                    </div>
                  </div>
                </div>
              </DashboardBoxLayout>
              </ConditionalRender>
            </div>
          </DashboardBoxLayout>
        </div>

        <div className='flex-1'>
          <DashboardBoxLayout>
            <DashboardBoxTitle title='Accomplition' className='pb-3 !text-[--theme]' />
            <div className="flex flex-col gap-3">

              {
                (data as any).filter((section: any) => section.head_section === 'Accomplition')?.map((section: any) => {
                  return (
                    <DashboardBoxLayout className='h-[300px] ' key={section.id}>
                      <div className='min-h-[90px] pb-[5px]'>
                        <div className='flex gap-3 items-center justify-between'>
                          <div className='cursor-pointer' onClick={() => { setUpdateSuccessModalData({ section_id: section.id, name: section.section }) }}>
                            <span className='text-[20px] font-inter-medium text-primaryTextColor'>{t(section.section)}</span>
                          </div>
                          <ConditionalRender condition={hasPermissionToCreateSuccess}>
                          <div onClick={() => { setIsSuccessCompanyModalOpen({ section_id: section.id, section_name: section.section }) }}
                            className='flex-none h-[36px] w-[36px] rounded-[8px] bg-[--theme] grid place-items-center cursor-pointer'>
                            <Icon iconType={IconType.PLUS_ICON} />
                          </div>
                          </ConditionalRender>
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 verticalOverflowScrollStyle h-[165px]'>
                        {
                          (section as any).data?.map((data: any) => {
                            return (

                              <div className='flex items-center justify-between font-inter-regular gap-2 cursor-pointer'
                                onClick={() => { setUpdateSuccessCompanyModalData({ section_id: section.id, section_name: section.section, data: data }) }}>

                                <div className='h-[30px] w-[30px] flex-none'>
                                  <img
                                    src={'https://devmainhub.insusales.com/api/partners/logo/' + data.partner_id.logo}
                                    alt=""
                                    className='h-full w-full object-contain rounded-full'
                                  />
                                </div>
                                <span className='flex-1'>
                                  {data.partner_id.name}
                                </span>
                                <span className='font-inter-semibold text-[--theme]'>
                                  {data.amount} CHF
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </DashboardBoxLayout>
                  )
                })
              }
              <ConditionalRender condition={hasPermissionToCreateSuccess}>
              <DashboardBoxLayout className='border-dashed border-2 !border-[#D8D8D8] cursor-pointer'>
                <div className='h-[260px] grid place-items-center' onClick={() => { setIsSuccessModalOpen('Accomplition') }}>

                  <div className='flex flex-col gap-3 items-center'>
                    <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                    <div>
                      <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>

                    </div>
                  </div>
                </div>
              </DashboardBoxLayout>
              </ConditionalRender>
            </div>
          </DashboardBoxLayout>
        </div>

        <div className='flex-1'>
          <DashboardBoxLayout>
            <DashboardBoxTitle title='Commissions' className='pb-3 !text-[--theme]' />
            <div className="flex flex-col gap-3">

              {
                (data as any).filter((section: any) => section.head_section === 'Commissions')?.map((section: any) => {
                  return (
                    <DashboardBoxLayout className='h-[300px] ' key={section.id}>
                      <div className='min-h-[90px] pb-[5px]'>
                        <div className='flex gap-3 items-center justify-between'>
                          <div className='cursor-pointer' onClick={() => { setUpdateSuccessModalData({ section_id: section.id, name: section.section }) }}>
                            <span className='text-[20px] font-inter-medium text-primaryTextColor'>{t(section.section)}</span>
                          </div>
                          <ConditionalRender condition={hasPermissionToCreateSuccess}>
                          <div onClick={() => { setIsSuccessCompanyModalOpen({ section_id: section.id, section_name: section.section }) }}
                            className='flex-none h-[36px] w-[36px] rounded-[8px] bg-[--theme] grid place-items-center cursor-pointer'>
                            <Icon iconType={IconType.PLUS_ICON} />
                          </div>
                          </ConditionalRender>
                        </div>
                      </div>
                      <div className='flex flex-col gap-3 verticalOverflowScrollStyle h-[165px]'>
                        {
                          (section as any).data?.map((data: any) => {
                            return (

                              <div className='flex items-center justify-between font-inter-regular gap-2 cursor-pointer'
                                onClick={() => { setUpdateSuccessCompanyModalData({ section_id: section.id, section_name: section.section, data: data }) }}>

                                <div className='h-[30px] w-[30px] flex-none'>
                                  <img
                                    src={'https://devmainhub.insusales.com/api/partners/logo/' + data.partner_id.logo}
                                    alt=""
                                    className='h-full w-full object-contain rounded-full'
                                  />
                                </div>
                                <span className='flex-1'>
                                  {data.partner_id.name}
                                </span>
                                <span className='font-inter-semibold text-[--theme]'>
                                  {data.amount} CHF
                                </span>
                              </div>
                            )
                          })
                        }
                      </div>
                    </DashboardBoxLayout>
                  )
                })
              }
              <ConditionalRender condition={hasPermissionToCreateSuccess}>
              <DashboardBoxLayout className=' border-dashed border-2 !border-[#D8D8D8] cursor-pointer'>
                <div className='h-[260px] grid place-items-center' onClick={() => { setIsSuccessModalOpen('Commissions') }}>
                  <div className='flex flex-col gap-3 items-center'>
                    <Icon iconType={IconType.PLUS_ICON} color='#979797' className='h-[38px] w-[38px]' />
                    <div>
                      <span className='text-[#979797] font-inter-regular'>{t('Add new section')} </span>
                    </div>
                  </div>
                </div>
              </DashboardBoxLayout>
              </ConditionalRender>
            </div>
          </DashboardBoxLayout>

        </div>

      </div>
      <AddNewSuccessModal
        hocCurrentStep={ModalStep}
        setStepOnClose={1}
        currentStep={ModalStep}
        setCurrentStep={setModalStep}
        onConfirm={() => { setIsSuccessModalOpen(undefined); setUpdateSuccessModalData(undefined); setModalStep(1) }}
        hocisOpen={IsSuccessModalOpen !== undefined || UpdateSuccessModalData !== undefined}
        section={IsSuccessModalOpen || UpdateSuccessModalData}
        data={UpdateSuccessModalData}
        hocToggleModal={() => { setIsSuccessModalOpen(undefined); setUpdateSuccessModalData(undefined); setModalStep(1) }}
        hocTitle={ModalStep === 1 ? UpdateSuccessModalData !== undefined ? "Update Section" : "Add New Section" : ''}
        hocClasses={{
          modalClassName: "w-full md:max-w-[500px]",
          titleClassName: ""
        }}
      />

      <AddNewSuccessCompanyModal
        hocCurrentStep={ModalStep}
        setStepOnClose={1}
        currentStep={ModalStep}
        setCurrentStep={setModalStep}
        data={UpdateSuccessCompanyModalData?.data}
        section_id={IsSuccessCompanyModalOpen?.section_id || UpdateSuccessCompanyModalData?.section_id}
        onConfirm={() => { setIsSuccessCompanyModalOpen(undefined); setUpdateSuccessCompanyModalData(undefined); setModalStep(1) }}
        hocisOpen={IsSuccessCompanyModalOpen !== undefined || UpdateSuccessCompanyModalData !== undefined}
        hocToggleModal={() => { setIsSuccessCompanyModalOpen(undefined); setUpdateSuccessCompanyModalData(undefined); setModalStep(1) }}
        hocTitle={
          ModalStep === 1 ? (IsSuccessCompanyModalOpen?.section_name || UpdateSuccessCompanyModalData?.section_name) : ''
        }
        hocClasses={{
          modalClassName: "w-full md:max-w-[500px]",
          titleClassName: ""
        }}
      />
    </div>

  )
}

export default FinancesSuccessView