import Button from "components/atoms/button/Button";
import ErrorState from "components/atoms/error-state/ErrorState";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/text-area/TextArea";
import { IUser } from "components/molecules/user-group/UsersGroup";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FieldArrayPath, useFieldArray, useForm } from "react-hook-form";
import AutocompleteInput from "components/atoms/input/autocomplet-input/AutoCompletInput";
import Label from "components/atoms/input/input-label/InputLabel";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

//need's refactoring
type Worker = {
  id: string;
  name: string;
};

interface ICreateUserGroupFormProps {
  formData: any;
  onConfirm: () => void;
  setState: Dispatch<SetStateAction<IUser[]>>;
  state: Array<IUser>;
  editingUser: IUser | null;
  setSelectedUser?: any;
  selectedWorkersByRole: any;
  setSelectedWorkersByRole: any;
}

const CreateUserGroupForm: FC<ICreateUserGroupFormProps> = ({
  formData,
  onConfirm,
  setState,
  editingUser,
  state,
  selectedWorkersByRole,
  setSelectedWorkersByRole,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { currentStep } = useSignupCustomHooks({ initalStep: undefined });

  const {t} = useTranslation();

  const schema = yup.object().shape({
    notes: yup.string().optional(),
    roles: yup.string().required(t("Required field")),
    grouproles: yup.object().shape({
      roles: yup.string().optional(),
      workers: yup.array().min(1, t("Required field")),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors: formErrors },
    reset,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { append, fields, remove } = useFieldArray<any>({
    control,
    name: "grouproles.workers" as FieldArrayPath<Worker>,
  });

  useEffect(() => {
    setSelectedWorkersByRole({});
    setErrors([]);
    if (editingUser) {
      reset({
        roles: editingUser.roles,
        grouproles: {
          roles: editingUser.grouproles?.roles || "",
          workers: editingUser.workers || [],
        },
        notes: editingUser.notes,
      });
    } else {
      reset({
        roles: "",
        grouproles: {
          roles: "",
          workers: [],
        },
        notes: "",
      });
    }
  }, [editingUser, reset, append, setSelectedWorkersByRole, currentStep]);

  const onSubmit = () => {
    const formData = getValues();
    const roleName = formData.roles.trim();

    // Extract existing roles from the current state
    const roleExists = state.some(
      (user) => user.id !== editingUser?.id && user.roles.trim() === roleName
    );
    if (editingUser) {
      if (roleName !== editingUser.roles) {
        // Role name has changed, perform validation
        if (roleExists) {
          setErrors([...errors, `${t('Role')} ${roleName} ${t('already exists')}!`]);
          return;
        }
      }

      // Update the user
      setState((prevState: any[]) =>
        prevState.map((user) =>
          user.id === editingUser.id
            ? {
                ...user,
                roles: formData.roles,
                notes: formData.notes,
                workers: [...(formData.grouproles.workers ?? [])],
              }
            : user
        )
      );
    } else {
      // Add new user
      if (roleExists) {
        setErrors([...errors, `${t('Role')} ${roleName} ${t('already exists')}!`]);
        return;
      }

      setState((prevState: any[]) => [
        ...prevState,
        {
          id: prevState.length + 1,
          roles: formData.roles,
          notes: formData.notes,
          workers: [...(formData.grouproles.workers ?? [])],
          grouproles: {
            roles: formData.grouproles.roles,
            workers: [...(formData.grouproles.workers ?? [])],
          },
        },
      ]);
    }

    reset({
      roles: "",
      grouproles: {
        roles: "",
        workers: [],
      },
      notes: "",
    });
    onConfirm();
  };
  const [inputKey, setInputKey] = useState(0);

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e?.preventDefault();

      const target = e.target as HTMLInputElement;
      const value = target?.value?.trim();
      const { grouproles } = getValues();
      const roleName = (grouproles?.roles ?? "").trim();

      if (value && roleName) {
        const worker = formData?.workers?.find(
          (worker: any) => worker?.name === value
        );

        if (worker) {
          // Check if the worker is already assigned to any role
          const isAssigned = state.some((user) =>
            user.workers.some(
              (assignedWorker) => (assignedWorker as any)?.name === value
            )
          );
          if (isAssigned) {
            setErrors([
              ...errors,
              `${value}${t('* is already assigned to another group!')}`,
            ]);
            return;
          }

          // Check if the worker is already assigned to the current role
          const isAlreadyInCurrentRole = fields.some(
            (field) => (field as any)?.name === value
          );
          if (isAlreadyInCurrentRole) {
            setErrors([
              ...errors,
              `${value}${t('* is already assigned to another group!')}`,
            ]);
            return;
          }

          const selectedWorkersSet =
            selectedWorkersByRole[roleName] || new Set();
          if (selectedWorkersSet.has(value)) {
            setErrors([
              ...errors,
              `${value}${t('* is already assigned to another group!')}`,
            ]);
            return;
          }

          setSelectedWorkersByRole({
            ...selectedWorkersByRole,
            [roleName]: new Set([...selectedWorkersSet, value]),
          });

          append(worker);
          setInputKey((prevKey) => prevKey + 1);

          target.value = "";
        }
      }
    }
  };

  
  const filteredSuggestions = useMemo(() => {
    return (
      formData?.workers
        ?.filter((worker: any) => {
          // Check if the worker is already assigned to any role
          const isAssigned = state.some((user) =>
            user.workers.some(
              (assignedWorker) => (assignedWorker as any)?.name === worker.name
            )
          );

          // Check if the worker is already assigned to the current role
          const isAlreadyInCurrentRole = fields.some(
            (field) => (field as any)?.name === worker.name
          );

          return !isAssigned && !isAlreadyInCurrentRole;
        })
        .map((worker: any) => worker.name) || []
    );
  }, [formData, state, fields]);

  const removeWorker = (index: number) => {
    const removedWorker = fields[index];

    if (removedWorker) {
      const workerName = (removedWorker as Worker).name;

      // Remove the worker from the list of selected workers
      const updatedSelectedWorkersByRole = { ...selectedWorkersByRole };
      for (const roleName in updatedSelectedWorkersByRole) {
        updatedSelectedWorkersByRole[roleName].delete(workerName);
      }
      setSelectedWorkersByRole(updatedSelectedWorkersByRole);

      // Remove any errors related to this worker
      setErrors(errors.filter((error) => !error.includes(`"${workerName}"`)));

      // Add the removed worker back to suggestions if not already assigned
      const isAssigned = state.some((user) =>
        user.workers.some(
          (assignedWorker) => (assignedWorker as any)?.name === workerName
        )
      );

      const isAlreadyInCurrentRole = fields.some(
        (field) => (field as any)?.name === workerName
      );

      if (!isAssigned && !isAlreadyInCurrentRole) {
        const updatedFormData = { ...formData };
        updatedFormData.workers.push({ name: workerName }); // Assuming workers is an array in formData

        // Update formData (assuming it's a state or context variable)
        setSelectedWorkersByRole(updatedFormData);
      }

      // Remove the worker from the form fields
      remove(index);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[22px] mt-12"
    >
      <Input
        label={t("Role name")}
        {...register("roles")}
        error={formErrors?.roles}
      />
      <div>
        <Label text={t("Assign users")} />
        <AutocompleteInput
          key={inputKey} // Use the state variable as key
          suggestions={filteredSuggestions || []}
          setValue={setValue as any}
          clearErrors={clearErrors}
          //@ts-ignore
          error={formErrors?.grouproles?.workers}
          onKeyDown={onKeyDown}
          {...register("grouproles.roles")}
        />
        {errors.map((error, index) => (
          <ErrorState key={`errors-key-${index}`} error={error} className="m-0">
            {error}
          </ErrorState>
        ))}
        <ul className="flex flex-wrap gap-2 mt-[8px]">
          {fields?.map((field, index) => (
            <li
              key={`keyword-key-${index}`}
              className="font-inter-bold pr-[25px] capitalize bg-[#F4F9FD] text-[#7D8592] relative text-center rounded-[8px] text-[14px] w-auto p-1"
            >
              {(field as Worker).name}
              <Button
                type="button"
                className="absolute top-[7px] right-[5px]"
                onClick={() => removeWorker(index)}
              >
                <Icon
                  iconType={IconType.CLOSE_ICON}
                  className="p-1"
                  color="#7D8592"
                />
              </Button>
            </li>
          ))}
        </ul>
      </div>
      <TextArea
        label="Notes"
        {...register("notes")}
        className="border-[1px] border-inputborder min-h-[111px] rounded-[8px]"
        error={formErrors?.notes}
      />
      <Button
        type="submit"
        className="flex items-center justify-center text-white p-3 ml-auto rounded-[8px] gap-2 bg-bluebutton w-40"
      >
        <Icon iconType={IconType.INCRASE_ICON} color="white" />
        {editingUser ? t("Save") : t("Create")}{" "}
      </Button>
    </Form>
  );
};

export default CreateUserGroupForm;
