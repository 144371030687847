import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import { useDeletePartner } from "lib/hooks/mutations/Partners/useDeletePartner";
import { useTranslation } from "react-i18next";

import { FC } from "react";

export interface IDeletePartnerProps<T> {
  onConfirm: () => void;
  onCancel: () => void;
  id?: number;
  data: T;
  themeColors: {
    primary: string;
  };
}

const DeletePartner: FC<IDeletePartnerProps<any>> = ({
  onConfirm,
  onCancel,
  id,
  data,
  themeColors,
}) => {
  const { mutateAsync: deletePartner, isLoading } = useDeletePartner();

  const deleteSource = () => {
    deletePartner(id)
      .then(() => {
        onConfirm();
      })
      .catch(() => {});
  };
  const {t} = useTranslation();
  return (
    <Form className="mb-0 flex flex-col gap-10">
      <div>
        <div className="text-[20px] font-inter-medium">
          {t("Are you sure you want to delete")}{" "}
          {data.name
            ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
            : "Not available"}{" "}
          {t("partner?")}
        </div>
      </div>
      <div className="flex gap-5 justify-end">
        <div>
          <IconButton
            secondary={true}
            onClick={() => {
              onCancel();
            }}
            type="button"
            className={`!text-[${themeColors.primary}]`}
          >
            {t("Cancel")}
          </IconButton>
        </div>
        <div>
          <IconButton
            onClick={deleteSource}
            type="button"
            disabled={isLoading}
            className={`bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          >
            {t("Delete")}
          </IconButton>
        </div>
      </div>
    </Form>
  );
};

export default DeletePartner;
