import IconButton from 'components/atoms/button/IconButton';
import Checkbox from 'components/atoms/checkbox/Checkbox';
import Icon from 'components/atoms/icon/Icon';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import SalariesTable from 'components/organisms/finance-salaries/salaries-table/SalariesTable';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useAssignSalary } from 'lib/hooks/mutations/Finances/useAssignSalary';
import { useSalaries } from 'lib/hooks/queries/Finances/useSalaries';
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';


interface IStep3ChooseRoleProps {
    setCurrentStep: (step: number) => void;
    setAllData: (data: any) => void;
    AllData: any;
    onConfirm: () => void;
}

const Step3ChooseSalary: FC<IStep3ChooseRoleProps> = ({ setCurrentStep, setAllData, AllData, onConfirm }) => {

    const { t } = useTranslation()

    const { data, isLoading } = useSalaries('')

    const TableHeaders = [
        {
            id: 1,
            Header: ``,
            accessor: `${t('Not available')}`,
            Cell: ({ row }: any) => (

                <Checkbox id={row.original.id}
                    isChecked={AllData?.salary_id === row.original.id}

                    onChange={(e: any) => {
                        if (e.target.checked) {
                            setAllData({ ...AllData, salary_id: row.original.id })
                        }
                        else {
                            setAllData({ ...AllData, salary_id: null })

                        }
                    }}
                />


            ),
            isChecked: true
        },

        {
            id: 2,
            Header: `${t('Name')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.name || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: `${t('Assigned Workers')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer flex">
                    {row.original?.assigned_users?.length > 0 ? (
                        row.original?.assigned_users.map((user: any, index: number) => (
                            index < 5 && (
                                user?.users?.user_information?.profil_picture !== null ? (
                                    <img src={'https://devmainhub.insusales.com/api/profile-picture/' + user?.users?.user_information?.profil_picture} alt=""
                                    className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full`} key={index} />
                                ) : (
                                    <img src={'https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg'} alt="" 
                                    className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full flex-none`} key={index} />

                                )
                            )
                        ))

                    ) : (
                        t('Not available')
                    )}

                    {(row.original?.assigned_users?.length > 5) && (
                        <div className="w-[35px] h-[35px] rounded-full -ml-3 bg-[--theme-bg-opacity] border-2 border-white text-[--theme] grid place-items-center font-inter-medium text-xs">
                            {row.original?.assigned_users.length - 5}
                        </div>
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: `${t('Performance Target')}`,
            accessor: (row: any) =>
                row?.performance_target,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.performance_target || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: `${t('Commission')}`,
            accessor: (row: any) =>
                row?.commission,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.commission || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: `${t('Bonus')}`,
            accessor: (row: any) =>
                row?.bonus,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.bonus || t('Not available')}
                </div>
            ),
            isChecked: true
        },

    ];

    const [ReRenderedCards, setReRenderedCards] = useState(TableHeaders)

    const { mutateAsync, isLoading: isLoadingSalary } = useAssignSalary()

    useEffect(() => {
        setReRenderedCards(TableHeaders)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AllData])

    const AssignSalary = () => {
        const finalData = {
            user_id: AllData.user_id,
            salary_id: AllData.salary_id
        }
        mutateAsync(finalData).then(() => {
            onConfirm()
        })
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
                <SalariesTable
                    data={data || []}
                    dataCols={ReRenderedCards}
                    background='transparent'
                />
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => { setAllData({ ...AllData, salary_id: null }) }} secondary disabled={isLoadingSalary}>
                    {t(`Reset`)}
                </IconButton>

                <IconButton
                    className=""
                    type='button'
                    onClick={AssignSalary}
                    disabled={AllData?.salary_id === null || AllData?.salary_id === undefined || isLoadingSalary}
                >
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </>
    )
}

export default Step3ChooseSalary