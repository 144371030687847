
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useStoreSuccess } from 'lib/hooks/mutations/Finances/useStoreSuccess';
import { useUpdateSection } from 'lib/hooks/mutations/Finances/useUpdateSection';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import { useDeleteSuccessSection } from 'lib/hooks/mutations/Finances/useDeleteSuccessSection';
import { usePermissions } from 'lib/hooks/shared/usePermissions';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';

export interface IAddNewSuccessModalProps {
    onConfirm: () => void;
    section?: string
    data?: any
    currentStep: number;
    setCurrentStep: (value: number) => void;
}
const AddNewSuccessModal: FC<IAddNewSuccessModalProps> = ({ onConfirm, section, data, currentStep, setCurrentStep }) => {

    const { t } = useTranslation();

    const hasPermissionToEdit = usePermissions(['performance_tracking_edit']);
    const hasPermissionToDelete = usePermissions(['performance_tracking_delete']);

    const { control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            section_id: data?.section_id,
            head_section: section,
            name: data?.name || '',
        }
    });

    const { mutateAsync, isLoading } = useStoreSuccess()

    const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } = useUpdateSection()

    const onSubmit = (formData: any) => {
        if (data) {
            updateAsync(formData).then(() => {
                onConfirm()
            })
        } else {
            mutateAsync(formData).then(() => {
                onConfirm()
            })
        }

    };

    const { mutateAsync: deleteFunction, isLoading: isLoadingDelete } = useDeleteSuccessSection()

    const [DeleteData, setDeleteData] = useState({ id: '', name: '' })

    return (
        currentStep === 1 ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-0 flex flex-col gap-5">
                    <Controller
                        name='name'
                        control={control}
                        rules={{ required: `${t('Section name is required')}` }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label={t('Section Name')}
                                placeholder={t('Enter section name')}
                                error={errors?.name}
                            />
                        )}
                    />


                </div>
                <div className="flex flex-row justify-end gap-5 pt-10">
                    {data && (
                        <ConditionalRender condition={hasPermissionToDelete}>
                        <IconButton
                            className="!w-full"
                            type="button"
                            secondary
                            disabled={isLoading || isLoadingUpdate}
                            onClick={() => { setCurrentStep(2); setDeleteData({ id: data.section_id, name: data?.name }) }}
                        >
                            Delete
                        </IconButton>
                        </ConditionalRender>
                    )}
                    <ConditionalRender condition={hasPermissionToEdit}>
                    <IconButton
                        className={`${data ? '!w-full' : ''}`}
                        type="submit"
                        icon={!data && <Icon iconType={IconType.PLUS_ICON} />}
                        disabled={isLoading || isLoadingUpdate}
                    >
                        {data ? t('Update section') : t('Add new section')}
                    </IconButton>
                    </ConditionalRender>
                </div>

            </Form >
        ) : (
            <DeleteModal
                onConfirm={onConfirm}
                cancelFunction={setCurrentStep}
                data={DeleteData}
                deleteFunction={deleteFunction}
                isLoading={isLoadingDelete}
            />
        )
    )
}

export default withModal(AddNewSuccessModal)