import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import withModal from 'lib/helpers/hoc/withModal'
import { useDeleteOverview } from 'lib/hooks/mutations/Documents/useDeleteOverview';
import { useDeleteOverviewDocument } from 'lib/hooks/mutations/Documents/useDeleteOverviewDocument';
import { useDeleteTemplate } from 'lib/hooks/mutations/Documents/useDeleteTemplate';
import React from 'react'

interface IDocumentDeleteModalProps {
    onConfirm: () => void;
    data: any
}

const DocumentDeleteModal: React.FC<IDocumentDeleteModalProps> = ({ onConfirm, data }) => {

    const { mutateAsync: deleteOverview, isLoading: isLoadingOverview } = useDeleteOverview()

    const { mutateAsync: deleteOverviewDocument, isLoading: isLoadingOverviewDocument } = useDeleteOverviewDocument()
    const { mutateAsync: deleteTemplate, isLoading: isLoadingTemplate } = useDeleteTemplate()

    return (
        <>
            <DeleteModal
                onConfirm={onConfirm}
                data={data}
                deleteFunction={
                    {
                        'overview': deleteOverview,
                        'overviewDocument': deleteOverviewDocument,
                        'template': deleteTemplate
                    }[data.deleteType as string] as any
                }
                isLoading={
                    {
                        'overview': isLoadingOverview,
                        'overviewDocument': isLoadingOverviewDocument,
                        'template': isLoadingTemplate,
                    }[data.deleteType as string]
                }
            />
        </>
    )
}

export default withModal(DocumentDeleteModal)