import {
  InputHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction
} from "react";
import { DeepMap, FieldError } from "react-hook-form";
import ErrorMessage from "../../atoms/error-message/ErrorMessage";
import Label from "components/atoms/input/input-label/InputLabel";

type TypeToggleSwitchClass = {
  label?: string;
  wrapper?: string;
};

interface IToggleSwitchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  initialValue?: boolean;
  className?: string;
  error?: DeepMap<any, FieldError>;
  classes?: Partial<TypeToggleSwitchClass>;
}
const ToggleSwitch: ForwardRefRenderFunction<
  HTMLInputElement,
  IToggleSwitchInputProps
> = (
  {
    label,
    initialValue = false,
    classes = { label: "", wrapper: "" },
    error,
    className,
    ...rest
  },
  ref
) => {


    return (
      <div className={`flex items-center ${classes.wrapper}`}>
        <Label className={`inline-flex items-center cursor-pointer ${classes.label}`}>
          <input
            type="checkbox"
            defaultChecked={initialValue}
            {...rest}
            ref={ref}
            className="sr-only peer"
          />
          <div
            className={`relative w-11 h-6 bg-[#DCDCDC] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[9%] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 peer:active:bg-[#DCDCDC] ${className}`}
          ></div>
          {label}
        </Label>
        <ErrorMessage error={error} />
      </div>
    );
  };
export default forwardRef(ToggleSwitch);
