import { apiRequest } from "./Api";

export const createNewAppointment = (data?: any) =>
  apiRequest<any, unknown>("POST", "appointment/store", data);

export const getAppointments = (params?: any) =>
  apiRequest<any, any>("GET", "appointment/own", null, undefined, params);

export const updateAppointment = (id: string, data?: any) =>
  apiRequest<any, any>("PUT", `appointment/update/${id}`, data);

export const requestForWonFeedback = (data: Record<string, any>) =>
  apiRequest<any, any>("POST", `appointment/feedback/won`, data);

export const requestForPendingFeedback = (data: Record<string, any>) =>
  apiRequest<any, any>("POST", `appointment/feedback/pending`, data);

export const requestForFollowUp = (data: Record<string, any>) =>
  apiRequest<any, any>("POST", `appointment/feedback/follow-up`, data);

export const requestForLost = (data: Record<string, any>) =>
  apiRequest<any, any>("POST", `appointment/feedback/lost`, data);

export const uploadAppointmentFile = (data: Record<string, any>) =>
  apiRequest<any, any>("POST", "appointment/document/upload", data);

export const deleteAppointmentDocument = (data: any) =>
  apiRequest<any, any>(
    "DELETE",
    `appointment/document/delete/${data.document_id}`,
    data
  );

export const getActivityLog = (params: any) =>
  apiRequest<any, any>("GET", "appointment/logs", null, undefined, params);

export const assignTo = (data: any) =>
  apiRequest<any, any>("POST", `appointment/assign`, data);

export const getAssigned = (params: any) =>
  apiRequest<any, any>("GET", "appointment", null, undefined, params);

export const getNotAssigned = (params: any) =>
  apiRequest<any, any>("GET", "appointment", null, undefined, params);

export const getNotAssignedDigital = (params: any) =>
  apiRequest<any, any>("GET", "appointment", null, undefined, params);

export const switchAppointment = (data: any) =>
  apiRequest<any, any>("POST", "appointment/switch", data);

export const uploadAppointmentFileManually = (data: any) =>
  apiRequest<any, any>("POST", "appointment/import/store-import-file", data, {
    "content-type": "multipart/form-data",
  });

export const dataOfCsv = (data: any) =>
  apiRequest<any, any>("POST", "appointment/import/csv-data", data);

export const storeUploadedAppointment = (data: any) =>
  apiRequest<FormData, any>("POST", "appointment/import/store", data);

export const returnAppointmentToList = (data: any) =>
  apiRequest<FormData, any>("POST", "appointment/unassign", data);

export const getStatisticCardsData = () =>
  apiRequest<any, any>("GET", "appointment/statistic/info-card");

export const getAppointmentDocument = (params?: any) =>
  apiRequest<any, any>("GET", "appointment/document", null, undefined, params);

export const getAppointmentNotes = (params?: any) =>
  apiRequest<any, any>("GET", "appointment/notes", null, undefined, params);

export const createNewAddress = (data: any) =>
  apiRequest<FormData, any>("POST", "appointment/address/store", data);

export const sendAppointmentNote = (data: { appointment_id: number, note: string, image?: string }) =>
  apiRequest<{ appointment_id: number, note: string, image?: string }, any>('POST', 'appointment/add-notes', data)

export const getAppointmentSource = (params?: any) =>
  apiRequest<any, any>("GET", "appointment/source", null, undefined, params);

