
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Input from 'components/atoms/input/Input';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useExternalInternalCost } from 'lib/hooks/mutations/Finances/useExternalInternalCost';
import { useUpdateExternalInternalCost } from 'lib/hooks/mutations/Finances/useUpdateExternalInternalCost';
import { useDeleteExternalInternalCost } from 'lib/hooks/mutations/Finances/useDeleteExternalInternalCost';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import { usePermissions } from 'lib/hooks/shared/usePermissions';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';

export interface IInternalCostsModalProps {
    onConfirm: () => void;
    data: any;
    currentStep: number
    setCurrentStep: (value: number) => void
}
const InternalCostsModal: FC<IInternalCostsModalProps> = ({ onConfirm, data, currentStep, setCurrentStep }) => {

    const { t } = useTranslation();

    const hasPermissonToEditCost = usePermissions(['cost_management_edit']);
    const hasPermissonToDeleteCost = usePermissions(['cost_management_delete']);

    const { control,
        formState: { errors },
        handleSubmit,
        register,
    } = useForm({
        defaultValues: {
            id: data?.id || null,
            type: data?.type || '',
            amount: data?.amount || '',
        }
    });

    const { mutateAsync, isLoading } = useExternalInternalCost('internalCosts')

    const { mutateAsync: updateAsync, isLoading: isLoadingAsync } = useUpdateExternalInternalCost('internalCosts')

    const onSubmit = (formData: any) => {

        const finalData = {
            ...formData,
            type_of_overview: 'Internal',
        }
        if (data) {
            updateAsync(finalData).then(() => {
                onConfirm();
            })
        } else {
            mutateAsync(finalData).then(() => {
                onConfirm();
            })
        }

    };

    const { mutateAsync: deleteInternal, isLoading: isLoadingDelete } = useDeleteExternalInternalCost(false)

    const [DeleteData, setDeleteData] = React.useState<any>({ id: '', name: '' })

    return (
        <>
            {
                currentStep === 1 ? (
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-0 flex flex-col gap-5">
                            <Controller
                                name='type'
                                control={control}
                                rules={{ required: `${t('Type is required')}` }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        label={t('Type')}
                                        placeholder={t('Enter cost type')}
                                        error={errors?.type}
                                    />
                                )}
                            />
                            <Controller
                                name='amount'
                                control={control}
                                rules={{
                                    required: `${t('Amount is required')}`,
                                    pattern: { value: /^[0-9]+$/, message: `${t('Amount must be a number')}` }
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        label={t('Amount')}
                                        placeholder={t('Enter the amount')}
                                        {...register("amount")}
                                        error={errors?.amount}

                                    />
                                )}
                            />

                        </div>
                        <div className="flex flex-row justify-end gap-5 pt-10">
                            {data && (
                                <ConditionalRender condition={hasPermissonToDeleteCost}>
                                <IconButton
                                    className="!w-full"
                                    type="button"
                                    secondary
                                    disabled={isLoading || isLoadingAsync}
                                    onClick={() => { setDeleteData({ id: data.id, name: data.type }); setCurrentStep(2) }}
                                    >
                                    Delete
                                </IconButton>
                                </ConditionalRender>
                            )}
                            <ConditionalRender condition={hasPermissonToEditCost}>
                            <IconButton
                                className={`${data ? '!w-full' : ''}`}
                                type="submit"
                                icon={!data && <Icon iconType={IconType.PLUS_ICON} />}
                                disabled={isLoading || isLoadingAsync}
                                >
                                {data ? t('Update internal cost') : t('Add new internal cost')}
                            </IconButton>
                            </ConditionalRender>
                        </div>

                    </Form >
                ) : (
                    <DeleteModal
                        onConfirm={onConfirm}
                        cancelFunction={setCurrentStep}
                        data={DeleteData}
                        deleteFunction={deleteInternal}
                        isLoading={isLoadingDelete}
                    />
                )
            }
        </>
    )
}

export default withModal(InternalCostsModal)