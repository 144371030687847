import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import { customStyles } from "lib/helpers/configs/customStyles";
import withModal from "lib/helpers/hoc/withModal";

import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useExternalInternalCost } from "lib/hooks/mutations/Finances/useExternalInternalCost";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useUpdateExternalInternalCost } from "lib/hooks/mutations/Finances/useUpdateExternalInternalCost";
import DeleteModal from "components/molecules/delete-modal/DeleteModal";
import { useDeleteExternalInternalCost } from "lib/hooks/mutations/Finances/useDeleteExternalInternalCost";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";

export interface IExternalCostsModalProps {
  onConfirm: () => void;
  data: any;
  currentStep: number;
  setCurrentStep: (value: number) => void;
}
const ExternalCostsModal: FC<IExternalCostsModalProps> = ({ onConfirm, data, currentStep, setCurrentStep }) => {
  const { t } = useTranslation();

  const hasPermissonToEditCost = usePermissions(['cost_management_edit']);
  const hasPermissonToDeleteCost = usePermissions(['cost_management_delete']);

  const { data: partnersData, isLoading: isLoadingPartners } = usePartners();

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: data
      ? {
        id: data.id,
        type: data?.type === 'Appointment costs' || data?.type === 'Lead costs' ? data?.type : 'Add other',
        other_type: data?.type,
        partner: data?.partner_id,
        unit: data?.unit,
        cost_per_unit: data?.cost_per_unit,
        piece: data?.piece,
        amount: data?.amount,
      }
      : {
        type: null,
        other_type: "",
        partner: null,
        unit: "",
        cost_per_unit: "",
        piece: "",
        amount: "",
      }
  });

  const { mutateAsync, isLoading } = useExternalInternalCost();
  const { mutateAsync: updateAsync, isLoading: updateIsLoading } = useUpdateExternalInternalCost();


  const onSubmit = (formData: any) => {
    const finalData = {
      ...formData,
      type_of_overview: "External",
      type: formData.type !== 'Add other' ? formData.type : formData.other_type
    };

    if (data) {
      updateAsync(finalData).then(() => {
        onConfirm();
      });
    } else {
      mutateAsync(finalData).then(() => {
        onConfirm();
      });
    }


  };

  const { mutateAsync: deleteExternal, isLoading: isLoadingDelete } = useDeleteExternalInternalCost(true)

  const [ModalData, setModalData] = useState({ id: '', name: '' })

  if (isLoadingPartners) {
    return <LoadingScreen />
  }

  return (
    currentStep === 1 ? (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-0 flex flex-col gap-5">
          <div className="relative">
            <Label text={t("Type")} />
            <Controller
              name="type"
              control={control}
              rules={{ required: `${t("Type is required")}` }}
              render={({ field }) => (
                <Creatable<any, false>
                  {...field}
                  placeholder={t("Choose cost type")}
                  options={[
                    { value: "Lead costs", label: t("Lead costs") },
                    { value: "Appointment costs", label: t("Appointment costs") },
                    { value: "Add other", label: t("Add other") },
                  ]}
                  value={
                    field?.value
                      ? {
                        value: field?.value,
                        label: t(field?.value),
                      }
                      : null
                  }
                  className="cursor-pointer font-inter-regular react-select-container"
                  classNamePrefix="react-select"
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : null;
                    field.onChange(selectedValue);
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
            {errors.type?.message && (
              <>
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  <>{errors.type?.message}</>
                </div>
              </>
            )}
          </div>
          {watch("type") === "Add other" && (
            <Controller
              name="other_type"
              control={control}
              rules={{ required: `${t("Type is required")}` }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t("Type")}
                  placeholder={t("Enter cost type")}
                  error={errors?.other_type}
                />
              )}
            />
          )}

          <div className="relative">
            <Label text={t("Partner")} />
            <Controller
              name="partner"
              control={control}
              rules={{ required: `${t("Partner is required")}` }}
              render={({ field }) => (
                <Creatable<any, false>
                  {...field}
                  placeholder={t("Choose partner")}
                  options={
                    (
                      partnersData?.partners as unknown as Array<{
                        name: string;
                        id: string;
                      }>
                    )?.map?.((user) => ({
                      label: user?.name,
                      value: user?.id,
                    })) ?? []
                  }
                  value={
                    field?.value
                      ? {
                        value: field?.value,
                        label:
                          (
                            partnersData?.partners?.find?.(
                              (user: any) => user.id === field.value
                            ) as { name: string } | undefined
                          )?.name || field.value,
                      }
                      : null
                  }
                  className="cursor-pointer font-inter-regular react-select-container"
                  classNamePrefix="react-select"
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : null;
                    field.onChange(selectedValue);
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
            {errors.partner?.message && (
              <>
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  <>{errors.partner?.message}</>
                </div>
              </>
            )}
          </div>
          <Controller
            name="unit"
            control={control}
            rules={{ required: `${t("Unit cost is required")}` }}
            render={({ field }) => (
              <Input
                {...field}
                label={t("Unit")}
                placeholder={t("Enter cost unit")}
                error={errors?.unit}
              />
            )}
          />
          <Controller
            name="cost_per_unit"
            control={control}
            rules={{
              required: `${t("Cost per unit is required")}`,
              pattern: {
                value: /^[0-9]+$/,
                message: `${t("Cost per unit must be a number")}`,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t("Cost/Unit")}
                placeholder={t("Enter cost per unit")}
                error={errors?.cost_per_unit}
              />
            )}
          />
          <Controller
            name="piece"
            control={control}
            rules={{
              required: `${t("Piece is required")}`,
              pattern: {
                value: /^[0-9]+$/,
                message: `${t("Piece must be a number")}`,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t("Piece")}
                placeholder={t("Enter piece number")}
                {...register("piece")}
                error={errors?.piece}
              />
            )}
          />
          <Controller
            name="amount"
            control={control}
            rules={{
              required: `${t("Amount is required")}`,
              pattern: {
                value: /^[0-9]+$/,
                message: `${t("Amount must be a number")}`,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t("Amount")}
                placeholder={t("Enter the amount")}
                {...register("amount")}
                error={errors?.amount}
              />
            )}
          />
        </div>
        <div className="flex flex-row justify-end gap-5 pt-10">
          {data && (
            <ConditionalRender condition={hasPermissonToDeleteCost}>
            <IconButton
              className="!w-full"
              type="button"
              secondary
              disabled={isLoading || updateIsLoading}
              onClick={() => { setCurrentStep(2); setModalData({ id: data.id, name: 'cost' }) }}
            >
              Delete
            </IconButton>
            </ConditionalRender>
          )}
          <ConditionalRender condition={hasPermissonToEditCost}>
          <IconButton
            className={`${data ? '!w-full' : ''}`}
            type="submit"
            icon={!data && <Icon iconType={IconType.PLUS_ICON} />}
            disabled={isLoading || updateIsLoading}
          >
            {data ? t("Update external cost") : t("Add new external cost")}
          </IconButton>
          </ConditionalRender>
        </div>
      </Form>
    ) : (
      <DeleteModal
        onConfirm={onConfirm}
        cancelFunction={setCurrentStep}
        data={ModalData}
        deleteFunction={deleteExternal}
        isLoading={isLoadingDelete}
      />
    )
  );
};

export default withModal(ExternalCostsModal);
