import IconButton from 'components/atoms/button/IconButton';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import Label from 'components/atoms/input/input-label/InputLabel';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import ImageDropZone from 'components/molecules/image-dropzone/ImageDropZone';
import { IconType } from 'lib/helpers/constants/iconTypes';
import withModal from 'lib/helpers/hoc/withModal'
import { useDeleteResource } from 'lib/hooks/mutations/Documents/useDeleteResource';
import { useUpdateResource } from 'lib/hooks/mutations/Documents/useUpdateResource';
import { usePermissions } from 'lib/hooks/shared/usePermissions';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IUpdateCompanyProps {
    onConfirm: () => void;
    data: any

}

const UpdateCompany: React.FC<IUpdateCompanyProps> = ({ onConfirm, data }) => {

    const { t } = useTranslation()

    const hasPermissionsToDeleteResources = usePermissions([
        "manage_resources_delete",
      ]);

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ defaultValues: { company_name: data.name, company_link: data.link_to, company_logo: data.file } });

    const { mutateAsync, isLoading } = useUpdateResource()

    const [EditLogo, setEditLogo] = useState(false);


    const onSubmit = (formData: any) => {

        const finalData = {
            id: data.id,
            name: formData.company_name,
            link_to: formData.company_link,
            company_logo: formData.company_logo
        }

        mutateAsync(finalData).then(() => {
            onConfirm()
        })

    }

    const [Step, setStep] = useState(1)

    const { mutateAsync: deleteResource, isLoading: isLoadingDelete } = useDeleteResource()

    if (Step === 1) {
        return (
            <Form onSubmit={handleSubmit(onSubmit)} >
                <div className="mb-0 flex flex-col gap-7">

                    {EditLogo ? (
                        <div className="relative" style={{ width: "fit-content" }}>
                            <ImageDropZone
                                label={t("Company logo")}
                                {...register("company_logo")}
                                rules={{ required: t('Company Logo is required*') }}
                                control={control as any}
                                classes={{
                                    container: "max-w-[150px] mr-auto",
                                    subContainer: "ml-0",
                                    wrapperLabelClassName: "!w-[100px] !h-[100px]",
                                }}
                                acceptParameters="image/png, image/jpg, image/jpeg"
                            />
                            <div
                                className="absolute top-[18px] right-0 cursor-pointer"
                                onClick={() => {
                                    setEditLogo(false);
                                }}
                            >
                                <Icon iconType={IconType.CLOSE_X_SM_ICON} />
                            </div>
                        </div>
                    ) : (
                        <div className="relative" style={{ width: "fit-content" }}>
                            <Label>{t('Company logo')}</Label>
                            <img
                                src={`https://devmainhub.insusales.com/api/insurance-management/${data.file}`}
                                className="object-cover rounded-[10px] w-[100px] h-[100px]"
                                alt="img"
                            />
                            <div
                                className="absolute top-1 right-[-15px] cursor-pointer"
                                onClick={() => {
                                    setEditLogo(true);
                                }}
                            >
                                <Icon iconType={IconType.EDIT_SOURCE_ICON} />
                            </div>
                        </div>
                    )}

                    <Controller
                        control={control}
                        name='company_name'
                        rules={{ required: t('Company Name is required') }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                value={field.value}
                                label={t('Company Name')}
                                className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                                placeholder={t('Enter Company Name')}
                                error={(errors as any)?.company_name}
                            />
                        )}


                    />
                    <Controller
                        control={control}
                        name='company_link'
                        rules={{ required: t('Company Link is required') }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                value={field.value}
                                label={t('Company Link')}
                                className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                                placeholder={t('Enter Company Link')}
                                error={(errors as any)?.company_link}
                            />
                        )}


                    />

                    <div className='flex-none flex justify-end gap-3'>
                        <ConditionalRender condition={hasPermissionsToDeleteResources}>  
                        <IconButton
                            type='button'
                            onClick={() => setStep(2)}
                            disabled={isLoading}
                            className='w-full'
                            secondary
                            icon={<Icon color="var(--theme)" iconType={IconType.DELETE_ICON} />}
                            >
                            {t('Delete')}
                        </IconButton>
                        </ConditionalRender>
                        <IconButton
                            type='submit'
                            disabled={isLoading}
                            className='w-full'
                            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                        >
                            {t('Update Company')}
                        </IconButton>
                    </div>
                </div>
            </Form >
        )
    } else {
        return (
            <DeleteModal
                onConfirm={onConfirm}
                deleteFunction={deleteResource}
                data={data}
                isLoading={isLoadingDelete}
            />
        )
    }

}

export default withModal(UpdateCompany)