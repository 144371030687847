
import withModal from 'lib/helpers/hoc/withModal'
import React, { FC, useState } from 'react'
import Step1EmployeeInfo from './add-new-employee-steps/Step1EmployeeInfo';
import Step2ChooseRole from './add-new-employee-steps/Step2ChooseRole';
import Step3ChooseSalary from './add-new-employee-steps/Step3ChooseSalary';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import Step2CreateRole from './add-new-employee-steps/Step2CreateRole';


export interface IAddNewEmployeeProps {
    onConfirm: () => void;
    currentStep: number;
    setCurrentStep: (step: number) => void;
}
const AddNewEmployee: FC<IAddNewEmployeeProps> = ({ onConfirm, currentStep, setCurrentStep }) => {

    const [AllData, setAllData] = useState({
        permission: [],
    })


    return (
        <>
            <ConditionalRender condition={currentStep === 2 || currentStep === 4}>
                <button
                    type="button"
                    className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
                    onClick={() => {
                        setCurrentStep(currentStep === 4 ? 2 : currentStep - 1);
                    }}
                >
                    <Icon
                        iconType={IconType.BACK_BUTTON_MODAL}
                        color={'var(--theme)'}
                    />
                </button>
            </ConditionalRender>
            {{
                1: <Step1EmployeeInfo setCurrentStep={setCurrentStep} setAllData={setAllData} AllData={AllData} />,
                2: <Step2ChooseRole setCurrentStep={setCurrentStep} AllData={AllData} setAllData={setAllData} onConfirm={onConfirm} />,
                3: <Step3ChooseSalary setCurrentStep={setCurrentStep} AllData={AllData} setAllData={setAllData} onConfirm={onConfirm} />,
                4: <Step2CreateRole setCurrentStep={setCurrentStep} AllData={AllData} setAllData={setAllData} onConfirm={onConfirm} />,
            }[currentStep]}
        </>
    )
}

export default withModal(AddNewEmployee)