import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import EmptyModule from 'components/molecules/empty-module/EmptyModule'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FinancesTable from '../tables/FinancesTable'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import InternalCostsModal from '../modals/InternalCostsModal'
import { useInternalCosts } from 'lib/hooks/queries/Finances/useInternalCosts'
import { usePermissions } from 'lib/hooks/shared/usePermissions'


type InternalCostsProps = {
    total: string
    filterData: { year: number, month: number }
}


const InternalCosts: FC<InternalCostsProps> = ({ total, filterData }) => {

    const { t } = useTranslation()

    const [UpdateModalData, setUpdateModalData] = useState()

    const leadsTableHeaders = [
        {
            id: 1,
            Header: `${t('Type')}`,
            accessor: (row: any) =>
                row.type,
            Cell: ({ row }: any) => (
                <div className='cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.type}
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: `${t('Total (CHF)')}`,
            accessor: (row: any) =>
                row.amount,
            Cell: ({ row }: any) => (
                <div className='font-inter-semibold text-[--theme] cursor-pointer' onClick={() => { setUpdateModalData(row.original) }}>
                    {row.original.amount} CHF
                </div>
            ),
            isChecked: true
        }
    ];


    const { data, isLoading } = useInternalCosts({ filter_date: filterData })

    const [IsModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [ModalStep, setModalStep] = useState(1)

    return (
        <DashboardBoxLayout>
            <div className='flex justify-between items-center gap-3 mb-5'>

                <DashboardBoxTitle title='Internal Costs' />
                <ConditionalRender condition={usePermissions(['cost_management_create'])}>
                <div className="bg-[--theme] h-[48px] w-[48px] grid place-items-center rounded-[8px] cursor-pointer flex-none"
                    onClick={() => setIsModalOpen(true)}
                >
                    <Icon iconType={IconType.PLUS_ICON} />
                </div>
                </ConditionalRender>
            </div>
            <ConditionalRender condition={isLoading}>
                <div className="relative py-10">
                    <LoadingScreen />
                </div>
            </ConditionalRender>
            <ConditionalRender condition={!isLoading}>
                {(data as any)?.data.length === 0
                    ? <EmptyModule title="Internal Costs" />
                    :
                    <>
                        <FinancesTable
                            data={(data as any)?.data || []}
                            dataCols={leadsTableHeaders}
                        />
                        <div className='border-t-[1px] border-[#A2A1A8] border-opacity-20 pt-5'>
                            <div className='flex items-center justify-between'>
                                <span className='font-inter-semibold'>Total</span>
                                <span className='font-inter-semibold text-[--theme]'>{total} CHF</span>

                            </div>
                        </div>
                    </>
                }

            </ConditionalRender>
            <InternalCostsModal
                hocCurrentStep={ModalStep}
                currentStep={ModalStep}
                clearData={true}
                setCurrentStep={setModalStep}
                setStepOnClose={1}

                data={UpdateModalData}
                onConfirm={() => { setIsModalOpen(false); setUpdateModalData(undefined); setModalStep(1) }}
                hocisOpen={IsModalOpen || UpdateModalData !== undefined}
                hocToggleModal={() => { setIsModalOpen(false); setUpdateModalData(undefined); setModalStep(1) }}
                hocTitle={
                    ModalStep === 1
                        ? UpdateModalData !== undefined
                            ? "Update Internal Cost"
                            : "Add Internal Cost"
                        : ""
                }
                hocClasses={{
                    modalClassName: "w-full md:max-w-[500px]",
                    titleClassName: ""
                }}
            />
        </DashboardBoxLayout>
    )
}

export default InternalCosts