import { editRolePermissions } from "api/Employee";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useEditRolePermissions = () => {
    const queryClient = useQueryClient();

    const {t} = useTranslation();
    return useMutation(editRolePermissions, {
        onSuccess: () => {
            toast.success("Updated successfully!");
            queryClient.invalidateQueries('rolesOfCompany')

        },
        onError: (e: any) => {
            toast.error(t(e?.response?.data?.message) || "Something went wrong! Please try again later.");
        },
    })
}