import { FC, useEffect, useState } from "react";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import Form from "../../../atoms/form/Form";
import { useMultiStepFormContext } from "../../../../lib/context/MultiStepFormContext/MultiStepFormContext";
import ModuleList from "components/molecules/module-list/ModuleList";
import UsersGroup, { IUser } from "components/molecules/user-group/UsersGroup";
import { useForm } from "react-hook-form";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import ErrorState from "components/atoms/error-state/ErrorState";

export interface ICreateUsersGroupStepProps { }

const CreateUsersGroupStep: FC<ICreateUsersGroupStepProps> = () => {
  const { currentStep, nextFormStep, updateFormData } =
    useMultiStepFormContext();

  const { store } = useSignupCustomHooks({ initalStep: undefined });
  const { handleSubmit } = useForm();
  const [state, setState] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<{ id: number } | null>(null);
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<any[]>(
    []
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (store.grouproles) {
      setState(store.grouproles);
      setSelectedUser(store.grouproles[0]);
      setSelectedUserPermissions(
        store.grouproles.reduce((acc: any, group: any) => {
          acc[group.id] = group.permission || [];
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formData } = useMultiStepFormContext<any>();

  const onSubmit = () => {
    const userGroupsData = state.map((group: any, index: number) => {
      return {
        id: index,
        roles: group.roles,
        notes: group.notes,
        workers: group.workers,
        permission: selectedUserPermissions[group.id] || [],
      };
    });

    const allUsersHavePermissions = userGroupsData.every(
      (group) => group.permission.length > 0
    );

    const assignedWorkerNames = userGroupsData.flatMap((group) =>
      group.workers.map((worker: any) => worker.name)
    );

    const allWorkersAssigned = formData?.workers?.every((worker: any) =>
      assignedWorkerNames?.includes(worker?.name)
    );

    if (!allUsersHavePermissions) {
      setError("All users must have at least one permission selected.");
      return;
    }

    if (!allWorkersAssigned) {
      const missingWorkers = formData.workers
        .filter((worker: any) => !assignedWorkerNames.includes(worker.name))
        .map((worker: any) => worker.name)
        .join(", ");
      setError(
        `All workers must be assigned to a group role. Missing workers: ${missingWorkers}`
      );
      return;
    }

    updateFormData({ grouproles: userGroupsData });
    nextFormStep();
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-between h-full"
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
          STEP {currentStep}/6
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Create your Role Models
        </span>
      </div>
      <div className="flex gap-[30px] m-auto !mt-[initial] w-full p-[40px] flex-col 2xl:flex-row">
        <div className="basis-2/5 h-full">

          <UsersGroup
            selectedUser={selectedUser}
            setState={setState}
            state={state}
            setSelectedUser={setSelectedUser}
          />
        </div>
        <ModuleList
          selectedUser={selectedUser}
          selectedUserPermissions={selectedUserPermissions as any}
          setSelectedUserPermissions={setSelectedUserPermissions as any}
        />
      </div>
      {error && (
        <ErrorState
          error={error}
          className="lg:max-w-[600px] max-w-[800px] 2xl:max-w-[650px] mx-auto"
        >
          {error}
        </ErrorState>
      )}
      <FormStepNavigationButtons onDraftButton={true} />
    </Form>
  );
};

export default CreateUsersGroupStep;
