import { useQuery } from "react-query"
import { getSelfProfile } from "api/Employee";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const useEmployeSelfProfile = (
    id: any
) => {
const permissions = usePermissions(['employee_management_view']);
return useQuery<Array<any> | any>(
  'employes-profile',
    () => getSelfProfile(permissions ? id : null),
    {
        onError(error: any) {
            console.error(error)
        }
    }
)
}