import EmployeesManagementView from "components/organisms/employees-management/EmployeesManagementView";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { FC } from "react";
import EmployeeProfileView from "components/organisms/employee-profile/EmployeeProfileView";

export interface IEmployeesManagementProps {}

const EmployeesManagement: FC<IEmployeesManagementProps> = () => {
  const hasPermissionToViewOwnEmployee = usePermissions([
    "employee_management_view_view_own",
  ]);

  const hasPermissionToViewAllEmployee = usePermissions([
    "employee_management_view",
  ]);
  return (
    <>
    
      {hasPermissionToViewOwnEmployee && !hasPermissionToViewAllEmployee ? (
        <EmployeeProfileView />
      ) : (
        <EmployeesManagementView />
      )}
    </>
  );
};

export default EmployeesManagement;