import { apiRequest } from "./Api";

export const createCustomer = (data: any) =>
  apiRequest<FormData, any>("POST", "costumers/store", data);

export const getCustomers = (params?: any) =>
  apiRequest<any, any>("GET", "costumers", null, undefined, params);

export const updateCustomer = (data: any) =>
  apiRequest<FormData, any>("POST", `costumers/update`, data);


export const getActivityLog = (params: any) =>
    apiRequest<any, any>("GET", "costumers/logs", null, undefined, params);
  
export const createNewProducts = (data: any) => 
  apiRequest<FormData, any>("POST", `costumers/product/store`, data);


export const getCustomerProduct = (params?: any) =>
  apiRequest<any, any>("GET", "costumers/product", null, undefined, params);

export const getCustomerDocument = (params?: any) =>
  apiRequest<any, any>("GET", "costumers/document", null, undefined, params);


export const storeNewDocument = (data?: any) =>
  apiRequest<any, any>("POST", "costumers/document/store", data);

export const updateCustomerProduct = (data?: any) =>
  apiRequest<any, any>("POST", "costumers/product/update", data);


export const deleteCustomerDocument = (data: any) =>
  apiRequest<any, any>("DELETE", `costumers/document/delete/${data.document_id}`, data);


export const showCustomerDocument = (link?: string) =>
  apiRequest<any, any>("GET", `costumers/document/${link}`);



export const getRequestsData =(params: any) => 
  apiRequest<any,any>('GET', 'costumers/requests',null, undefined, params)



export const requestFeedBack = (data: any) => 
  apiRequest<FormData,any>('POST','costumers/requests/feedback',data)



export const exportCustomer = (data: any) => 
  apiRequest<FormData,any>('POST', 'costumers/requests/store',data)


export const downloadFeedBack = (data: any) => 
  apiRequest<FormData,any>('POST',`costumers/requests/export`, data, undefined, undefined, 'blob')


export const getActivityLogRequestData = (params: any) => 
  apiRequest<any,any>('GET',`costumers/requests/logs`,null,undefined,params)



export const getStatisticInfoCards = () =>
  apiRequest<any, any>("GET", "costumers/statistic/info-card");


export const storeUploadedCustomers = (data: any) =>
  apiRequest<FormData, any>("POST", "costumers/import/store-file", data, {
    "content-type": "multipart/form-data"
  });


  export const dataOfCsv = (data: any) =>
    apiRequest<any, any>("POST", "costumers/import/csv-data", data);
  


  export const storeUploadedCustomerData = (data: any) =>
    apiRequest<FormData, any>("POST", "costumers/import/store", data);
  
  

  export const getRelatedCustomersForFilter = (params?: any) =>
    apiRequest<any, any>("GET", `costumers/for-filter` , null, undefined, params);

  export const getCustomersFamilyMembers = (params?: any) =>
    apiRequest<any, any>("GET", `costumers/family-members` , null, undefined, params);


  export const updateCustomerFamilyMember  = (data: Record<string, any>) =>
    apiRequest<any, any>("POST", `costumers/update-family-members`, data);

  export const getCustomerSource = (params?: any) =>
    apiRequest<any, any>("GET", `costumers/source` , null, undefined, params);
