import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getTemplates } from "api/Documents";
import { usePermissions } from "lib/hooks/shared/usePermissions";


interface FiltersProps {
    search?: string
}

export const useGetTemplates = (
    filters?: FiltersProps
) => {
    const hasPermissionToViewResources = usePermissions(['manage_templates_view']);

    const { data, isLoading } = useQuery<Array<any>>(
        ['templates', filters?.search],
        () => {
            if(hasPermissionToViewResources){
                return getTemplates({
                    search: filters?.search
                });
            }else{
                return undefined as any;
            }
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
