import Icon from "components/atoms/icon/Icon";
import { ISource } from "lib/types/sources.types";
import { ILead } from "lib/types/leads.types";
import { IconType } from "./iconTypes";
import ToggleSwitchComponent from "components/molecules/toggle-switch/ToggleSwitch";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { useEffect, useRef, useState } from "react";
import UpdateSource from "components/organisms/source/update-source/UpdateSource";
import AccessSource from "components/organisms/source/customer-access-source/AccessSource";
import DeleteSource from "components/organisms/source/delete-source/DeleteSource";
import { useUpdateSourceStatus } from "lib/hooks/mutations/Sources/useUpdateSourceStatus";
import React from "react";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const sourceTableHeaders = [
  {
    id: 1,
    Header: "Source",
    accessor: (row: ISource) =>
      row?.name || row?.logo ? (
        <div className="flex flex-row items-center gap-2 ">
          <ConditionalRender condition={row?.logo?.length > 0}>
            <img
              loading="lazy"
              className="object-fit rounded-full w-[30px] h-[30px]"
              src={`https://devmainhub.insusales.com/api/leads/sources/get-logo/${row.logo}`}
              alt="Logo"
            />
          </ConditionalRender>
          <div className="">{row.name}</div>
        </div>
      ) : (
        "Not available"
      ),
    isChecked: true,
  },
  {
    Header: "Created By",
    accessor: (row: any) => row?.created_by || "Not available",
    id: 55,
    isChecked: true,
  },
  {
    Header: "Total Leads",
    accessor: (row: ILead) => row.info?.email || "Not available",
    id: 3,
    Cell: ({ row }: any) => <span>{row.original.leads_count}</span>,
    isChecked: true,
  },
  {
    Header: "Creation Date",
    accessor: (row: ISource) =>
      new Date(row?.created_at).toLocaleDateString('de') || "Not available",
    id: 7,
    isChecked: true,
  },
  {
    Header: "Status",
    accessor: (row: ISource) => row.status === "1",
    id: 8,
    Cell: ({ row }: any) => (
      <ToggleSwitchComp
        id={row.original.id}
        value={row.original.status}
        original={row.original}
      />
    ),
    isChecked: true,
  },
  {
    Header: "",
    accessor: (row: ISource) => row.status === "1",
    id: 9,
    Cell: ({ row }: any) => (
      <ActionButton
        id={row.original.id}
        value={row.original.status}
        original={row.original}
      />
    ),
    isChecked: true,
  },
];

export const ActionButton = ({ original, id }: any) => {
  const [ThreeDotsOpen, setThreeDotsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<any>(null);
  const { confirm } = useModalContext();
  const { themeColors } = useThemeContext();
  const dropdownRef = useRef<any>(null);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 200;
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.right + window.scrollX - dropdownWidth,
      });
    }
    setThreeDotsOpen(!ThreeDotsOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ThreeDotsOpen &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setThreeDotsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ThreeDotsOpen]);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<any>();
  const { t } = useTranslation();

  const hasPermissionToEditSource = usePermissions(['source_management_edit']);
  const hasPermissionToDeleteSource = usePermissions(['source_management_delete']);

  

  const OpenCustomerAccessModal = async (source: any) => {
    try {
      await confirm({
        title: t('Customer Access'),
        classes: {
          modal: "max-w-[551px] md-max-w-full lg:max-w-[551px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <AccessSource
              source={source}
              onConfirm={props.onConfirm}
              themeColors={themeColors}
            />
          );
        },
      });
      handleClick();
    } catch (err) {
      return null;
    }
  };

  const OpenDeleteModal = async () => {
    try {
      await confirm({
        title: t("Are you sure?"),
        classes: {
          modal: "max-w-[551px] md-max-w-full lg:max-w-[551px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <DeleteSource
              themeColors={themeColors}
              onCancel={props.onCancel}
              id={id}
              onConfirm={props.onConfirm}
              data={original}
            />
          );
        },
      });
      handleClick();
    } catch (err) {
      return null;
    }
  };

  return (
    <div className="flex flex-row items-center w-fit">
      <div className="relative">
        <button
          ref={buttonRef}
          type="button"
          className="pl-5"
          onClick={handleClick}
        >
          <Icon iconType={IconType.OPTIONS_ICON} />
        </button>
        {ThreeDotsOpen &&
          ReactDOM.createPortal(
            <div
              style={{
                position: "absolute",
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
              }}
              ref={dropdownRef}
            >
              <div className="shadow-custom py-[15px] px-[21px]  w-[193px]  bg-white flex flex-col text-[14px] gap-1 font-inter-medium rounded-[12px]">
                <div>
                  <ConditionalRender condition={hasPermissionToEditSource}>
                    <div className="pb-3 flex flex-row items-center cursor-pointer">
                      <div>
                        <Icon iconType={IconType.EDIT_SOURCE_ICON} />
                      </div>
                      <div
                        className="px-4 text-[14px] font-inter-medium"
                        onClick={() => {
                          setIsUpdateModalOpen(true);
                          setUpdateData(original);
                        }}
                      >
                        {t('Edit')}
                      </div>
                    </div>
                    <div className="pb-3 flex flex-row items-center cursor-pointer whitespace-nowrap">
                      <div>
                        <Icon iconType={IconType.CUSTOMER_ACCES_ICON} />
                      </div>
                      <div
                        className="px-3 text-[14px] font-inter-medium"
                        onClick={() => {
                          setThreeDotsOpen(false);
                          OpenCustomerAccessModal(original);
                        }}
                      >
                        {t('Customer Access')}
                      </div>
                    </div>
                  </ConditionalRender>
                  <ConditionalRender condition={hasPermissionToDeleteSource}>
                    <div
                      className=" flex flex-row items-center cursor-pointer"
                      onClick={() => {
                        setThreeDotsOpen(false);
                        OpenDeleteModal();
                      }}
                    >
                      <div>
                        <Icon iconType={IconType.DELETE_SOURCE_ICON} />
                      </div>
                      <div className="px-3 text-[14px] font-inter-medium">
                        {t('Delete')}
                      </div>
                    </div>
                  </ConditionalRender>
                </div>
              </div>
            </div>,
            document.body
          )}
      </div>
      <ConditionalRender condition={isUpdateModalOpen as boolean}>
        <UpdateSource
          onConfirm={() => setIsUpdateModalOpen(false)}
          themeColors={themeColors}
          hocClasses={{ modalClassName: 'max-w-[551px]' }}
          source={updateData}
          hocCurrentStep={1}
          hocToggleModal={
            () => setIsUpdateModalOpen(false)
          }
          hocisOpen={isUpdateModalOpen}
          hocTitle="Create New Source"
          setHocCurrentStep={() => { }}
          clearData={true}
        />
      </ConditionalRender>
    </div>
  );
};

export const ToggleSwitchComp = ({ id, value, original }: any) => {


  const { mutateAsync: updateSourceStatus } = useUpdateSourceStatus();
  const [IsChecked, setIsChecked] = useState(value === 1);

  React.useEffect(() => {
    setIsChecked(value === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const toggleStatus = async (e: any) => {


    updateSourceStatus({
      source_id: id,
    })
      .then((res: any) => {
        if (res.status) {
          setIsChecked(!e.target.checked);
        }
      })
      .catch(() => { });


  };

  return (
    <div>
      <ToggleSwitchComponent
        checked={IsChecked}
        initialValue={"" as any}
        onChange={toggleStatus}
        classes={{ label: "!mb-0" }}
      />

    </div>
  );
};
