
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import withModal from 'lib/helpers/hoc/withModal'

import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import RangeSlider from 'components/atoms/range-slider/RangeSlider';
import { useEditCommission } from 'lib/hooks/mutations/Finances/useEditCommission';
import DeleteModal from 'components/molecules/delete-modal/DeleteModal';
import { useDeleteCommission } from 'lib/hooks/mutations/Finances/useDeleteCommission';
import { usePermissions } from 'lib/hooks/shared/usePermissions';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';

export interface IManageCommissionModuleProps {
    onConfirm: () => void;
    data: any,
    setIsAddCommissionCompanyOpen: (value: { id: string, name: string }) => void
    setIsAddCommissionOpen: (id: string) => void
    currentStep: number
    setCurrentStep: (value: number) => void
}
const ManageCommissionModule: FC<IManageCommissionModuleProps> = ({
    onConfirm,
    data,
    setIsAddCommissionCompanyOpen,
    setIsAddCommissionOpen,
    currentStep,
    setCurrentStep
}) => {

    const { t } = useTranslation();

    const hasPermissionToCreateCommission = usePermissions(['commission_models_create']);
    const hasPermissionToEditCommission = usePermissions(['commission_models_edit']);
    const hasPermissionToDeleteCommission = usePermissions(['commission_models_delete']);

    // Map commissions into an object keyed by `id`
    function mapCommissions() {
        const commissions: Record<number, any> = {};

        data.companies.forEach((company: any) => {
            company.companies_commissions.forEach((commission: any) => {
                commissions[commission.id] = {
                    company_commission: commission.company_commission
                };
            });
        });

        return commissions;
    }

    // Initialize react-hook-form with keyed default values
    const {
        control,
        handleSubmit,
    } = useForm({
        defaultValues: {
            commission_id: data.id,
            commissions: mapCommissions(), // Use the keyed object here
        }
    });

    const { mutateAsync, isLoading } = useEditCommission()

    const onSubmit = (formData: any) => {

        const finalData = {
            commission_id: formData.commission_id,
            commissions: Object.entries(formData.commissions).map(([key, value]: [any, any]) => ({
                finance_company_commission_id: key,
                company_commission: value.company_commission
            }))
        }
        mutateAsync(finalData).then(() => {
            onConfirm()
        })
    };

    const { mutateAsync: DeleteCommision, isLoading: isLoadingDelete } = useDeleteCommission()

    const [DeleteData, setDeleteData] = useState({ id: '', name: '' })

    return (
        <div className='relative'>
            {currentStep === 1 ? (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-0 flex flex-col gap-5">
                        <div className="flex flex-col gap-5">
                            {data.companies.map((company: any) => (
                                <div key={company.id}>
                                    <div className="text-xs text-primaryTextColor font-inter-semibold pb-3">
                                        {company.name}
                                    </div>
                                    <div className="flex gap-3 flex-wrap">
                                        {company.companies_commissions.map((commission: any) => (
                                            <div
                                                key={commission.id}
                                                className="border border-[#F2F4F7] px-5 py-3 rounded-[8px] w-full max-w-xs flex flex-col gap-5"
                                            >
                                                <div className="flex gap-3 w-full items-center">
                                                    <img
                                                        alt=""
                                                        className="h-[36px] w-[36px] object-contain rounded-full"
                                                        src={
                                                            'https://devmainhub.insusales.com/api/partners/logo/' +
                                                            commission.partner.logo
                                                        }
                                                    />
                                                    <span className="text-sm text-primaryTextColor font-inter-regular">
                                                        {commission.partner.name}
                                                    </span>
                                                    <ConditionalRender condition={hasPermissionToDeleteCommission}>
                                                    <div className="ml-auto">
                                                        <div className='cursor-pointer' onClick={() => {
                                                            setCurrentStep(2)
                                                            setDeleteData({ id: commission.id, name: commission.partner.name + ' ' + company.name })
                                                        }}>
                                                            <Icon iconType={IconType.DELETE_CAMPAIGN_FIELDS_ICON} />
                                                        </div>
                                                    </div>
                                                    </ConditionalRender>
                                                </div>
                                                <Controller
                                                    name={`commissions[${commission.id}].company_commission` as any} // Use `commission.id` as key
                                                    control={control}
                                                    defaultValue={
                                                        (control as any)._defaultValues.commissions[commission.id]
                                                            ?.company_commission || 0
                                                    } // Fallback to 0 if value is missing
                                                    render={({ field }) => (
                                                        <RangeSlider
                                                            value={field.value} // Bind value from react-hook-form
                                                            onChange={field.onChange} // Update value in react-hook-form
                                                            rightLabel="Salesmen"
                                                            leftLabel="Company Profit"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        ))}
                                        <ConditionalRender condition={hasPermissionToCreateCommission}>
                                        <div
                                            className="w-[44px] rounded-[8px] h-[135px] border border-dashed border-[#F2F4F7] flex items-center justify-center cursor-pointer"
                                            onClick={() => {
                                                onConfirm();
                                                setIsAddCommissionCompanyOpen({ name: company.name, id: company.id })
                                            }}
                                        >
                                            <Icon iconType={IconType.PLUS_ICON} color="#7D8592" />
                                        </div>
                                        </ConditionalRender>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-row justify-end gap-5 pt-10">
                        <ConditionalRender condition={hasPermissionToCreateCommission}>
                        <div className="flex gap-2 items-center cursor-pointer" onClick={() => {
                            onConfirm();
                            setIsAddCommissionOpen(data.id)
                        }}>
                            <Icon iconType={IconType.PLUS_ICON} color="#50B8E7" />
                            <span className="font-inter-semibold text-[--theme] cursor-pointer"  >
                                {t("Add new insurance company")}
                            </span>
                        </div>                        
                        </ConditionalRender>
                        <ConditionalRender condition={hasPermissionToEditCommission}>
                        <IconButton className="w-full max-w-[150px]" type="submit" disabled={isLoading}>
                            {t('Update')}
                        </IconButton>
                        </ConditionalRender>
                    </div>


                </Form>
            ) : (
                <DeleteModal
                    onConfirm={onConfirm}
                    data={DeleteData}
                    deleteFunction={DeleteCommision}
                    isLoading={isLoadingDelete}
                />
            )}


        </div>

    );
};

export default withModal(ManageCommissionModule);


