import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import CounterControl from "components/molecules/counter-control-append/CounterControlAppend";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useLeadsForm } from "lib/hooks/forms/useLeadsForm";
import { useCreateLeads } from "lib/hooks/mutations/Leads/useCreateLeads";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { useAllSources } from "lib/hooks/queries/Source/useAllSources";
import { customStyles } from "lib/helpers/configs/customStyles";
import { languageData } from "lib/helpers/constants/languageData";
import Creatable from "react-select/creatable";
import { numbersCode } from "lib/helpers/constants/numbersCode";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

export interface ICreateLeadProps {
  isAssignLeadsActive: string;
  state: any;
  setCurrentStep: any;
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
}

const CreateLead: FC<ICreateLeadProps> = ({
  isAssignLeadsActive,
  state,
  setCurrentStep,
  onConfirm,
  themeColors,
}) => {
  const defaultValue = numbersCode.find(item => item.value === '+41');
  const { mutate: createLead, isLoading } = useCreateLeads();
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const sources = useAllSources();
  

  const hasPermissionToAssignLead = usePermissions(['assign_assigned_leads_assign']);
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    watch,
  } = useLeadsForm({
    defaultValues: {
      persons: 0,
    },
  });

  useEffect(() => {
    getUsersByPermission({
      permissions: ['lead_view_view'],
    })
    .then((res) => {
      setFetchedUsers(res.users);
    })
    .catch(() => {});
    // eslint-disable-next-line
  }, []);

  let extraFields: { [key: string]: any } = {};

  const onSubmit = (data: any) => {
    const combinedPhoneNumber = `${data.code} ${data.phone}`;
    
    // eslint-disable-next-line
    state.map((item: any) => {
      extraFields[item?.data?.field_name] = {
        value: watch(item?.data?.field_name),
        default_text: item?.data?.default_text,
        required: item?.data?.required ? 1 : 0,
        section: item?.data?.section,
        field_type: item?.data?.field_type,
      };
    });
    const extraFieldNames = Object.keys(extraFields);

    // Destructure these fields out of data
    let dataWithoutExtraFields = { ...data };
    extraFieldNames.forEach((fieldName) => {
      delete dataWithoutExtraFields[fieldName];
    });

    const extraFieldsArray = Object.keys(extraFields).map((key) => ({
      section: extraFields[key].section,
      field_name: key,
      field_value: extraFields[key].value,
      default_text: extraFields[key].default_text,
      required: extraFields[key].required,
      field_type: extraFields[key].field_type,
    }));

    const formData = {
      ...dataWithoutExtraFields,
      phone: combinedPhoneNumber,
      extra_fields: extraFieldsArray,
    };
    delete formData?.code as any;
    onConfirm();
    reset();
    createLead(formData);
  };

  const [MapAddress, setMapAddress] = useState({
    postal_code: "",
    address: "",
    nr: "",
    city: "",
  });
  
  const formatOptionLabel = (data: any, { context }: any) => {
    if (context === "menu") {
      return (
        <div className="flex items-center">
          <Flag code={data.icon} height={14} width={14} />
          <span className="ml-2">{data.label}</span>
        </div>
      );
    } else {
      return (
        <div className="text-center mx-auto">
           {data.label}
        </div>
      )
    }
  };

  const {t} = useTranslation()
  
  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-0 flex flex-col gap-5"
      >
        <InputsFolder title={t('Personal information')}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <Input
              label={t('First Name')}
              placeholder={t('First Name')}
              {...register("first_name")}
              error={errors?.first_name}
              />
            <Input
              label={t('Last Name')}
              placeholder={t('Last Name')}
              {...register("last_name")}
              error={errors?.last_name}
            />
            <Input
              label={t('Nationality')}
              placeholder={t('Nationality')}
              {...register("nationality")}
              error={errors?.nationality}
            />
            <Controller
              control={control}
              name="birthdate"
              rules={{ required: "Date of birth is required" }}
              render={({ field: { onChange, value } }) => (
                <PrimaryDatePicker
                  label={t('Date Of Birth')}
                  startDate={value}
                  {...register("birthdate")}
                  setStartDate={(date: string) => onChange(date)}
                  error={errors.birthdate}
                />
              )}
            />
            <Input
              label={t('Current Health Insurance')}
              placeholder={t('Current Health Insurance')}
              {...register("health_insurance")}
              error={errors?.health_insurance}
            />
            <div>
              <Label>{t('Persons in Household')}</Label>
              <div className="w-full">
                <Controller
                  control={control}
                  name="persons"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CounterControl
                      count={value}
                      className="!mt-0"
                      classes={{
                        container: "w-full bg-white !border-inputborder",
                      }}
                      onIncrement={() => onChange(value + 1)}
                      onDecrement={() =>
                        onChange(value >= 1 ? value - 1 : value)
                      }
                    />
                  )}
                />
              </div>
            </div>
            <div className="relative">
              <Label text={t('Source')} />
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder={t('Choose source')}
                    options={sources?.data?.sources?.map?.((source: any) => ({
                      label: source.name ?? "",
                      value: source.id,
                    }))}
                    value={
                      field?.value
                        ? {
                            value: field?.value,
                            label:
                              (
                                sources?.data?.sources?.find?.(
                                  (item: any) => item.id === field.value
                                ) as { name: string } | undefined
                              )?.name || field.value,
                          }
                        : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
            </div>
            {state.map((item: any) => {
              if (item?.data?.section === "Personal information") {
                extraFields[item?.data?.field_name] = {
                  value: watch(item?.data?.field_name),
                };

                return (
                  <Input
                    label={item?.data?.field_name}
                    type={item?.data?.field_type === "text" ? "text" : "number"}
                    {...register(item?.data?.field_name, {
                      required: item?.data?.required
                        ? `${item?.data?.field_name} is required`
                        : false,
                    })}
                    required={item?.data?.required ? true : false}
                    error={errors?.[item?.data?.field_name]}
                  />
                );
              }
              return null;
            })}
          </div>
        </InputsFolder>
        <InputsFolder title={t('Contact information')}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div className="relative">
              <Label text={t('Language')} />
              <Controller
                name="language"
                control={control}
                rules={{ required: `${t('Language is required')}` }}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder={t('Choose language')}
                    options={languageData.map((language) => ({
                      value: language.value,
                      label: t(language.label),
                    }))}
                    value={
                      field.value
                        ? { value: field.value, label: t(field.value) }
                        : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              {errors.language?.message && (
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  <>{t(errors.language?.message as string)}</>
                </div>
              )}
            </div>
            <div className="grid grid-cols-[100px,auto] gap-[16px] items-end">
              <Controller
                name="code"
                control={control}
                defaultValue={defaultValue?.value} 
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    options={numbersCode.map((item) => ({
                      value: item.value,
                      label: item.label,
                      icon: item.icon
                    }))}
                    value={
                      field.value
                        ? numbersCode?.find(item => item.value === field.value)
                        : defaultValue || null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                    formatOptionLabel={formatOptionLabel}
                  />
                )}
              />
              <Input
                placeholder={t('Enter phone number')}
                {...register("phone")}
                error={errors.phone}
                // className={`border ${errors.phone ? 'border-red-500' : watch("phone") ? 'border-[#66BB6A]' : 'border-inputborder'}`}
              />
            </div>
            <Input
              placeholder={t('Enter email')}
              label={t('Email')}
              {...register("email")}
              error={errors?.email}
            />
            {state.map((item: any) => {
              if (item?.data?.section === "Contact information") {
                extraFields[item?.data?.field_name] = {
                  value: watch(item?.data?.field_name),
                  default_text: item?.data?.default_text,
                };
                return (
                  <Input
                    label={item?.data?.field_name}
                    type={item?.data?.field_type === "text" ? "text" : "number"}
                    required={item?.data?.required ? true : false}
                    {...register(item?.data?.field_name)}
                    error={errors?.[item?.data?.field_name]}
                  />
                );
              }
              return null;
            })}
          </div>
        </InputsFolder>
        <InputsFolder title={t('Address details')}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pb-2">
            <div className="flex flex-col gap-5">
              <Input
                placeholder={t('Enter street')}
                classes={{ icon: "!bg-white right-[8px]" }}
                label="Street"
                icon={IconType.LOCATION_ICON}
                {...register("address")}
                error={errors?.address}
                onChange={(e) => {
                  setMapAddress({ ...MapAddress, address: e.target.value });
                }}
              />
              <Input
                placeholder={t('Enter Nr')}
                label={t('Nr')}
                {...register("nr")}
                error={errors?.nr}
                onChange={(e) => {
                  setMapAddress({ ...MapAddress, nr: e.target.value });
                }}
              />
              <Input
                placeholder={t('Enter PLZ')}
                label={t('PLZ')}
                {...register("postal_code")}
                error={errors?.postal_code}
                onChange={(e) => {
                  setMapAddress({
                    ...MapAddress,
                    postal_code: e.target.value,
                  });
                }}
              />
              <Input
                placeholder={t('Enter City')}
                label={t('City')}
                {...register("city")}
                error={errors?.city}
                onChange={(e) => {
                  setMapAddress({ ...MapAddress, city: e.target.value });
                }}
              />
              {state.map((item: any) => {
                if (item?.data?.section === "Address details") {
                  extraFields[item?.data?.field_name] = {
                    value: watch(item?.data?.field_name),
                    default_text: item?.data?.default_text,
                  };
                  return (
                    <Input
                      label={item?.data?.field_name}
                      type={
                        item?.data?.field_type === "text" ? "text" : "number"
                      }
                      required={item?.data?.required ? true : false}
                      {...register(item?.data?.field_name)}
                      error={errors?.[item?.data?.field_name]}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div>
              <iframe
                className="border-inputborder border rounded-[8px]"
                title="map"
                src={`https://maps.google.com/maps?q='+${MapAddress.postal_code} ${MapAddress.address} ${MapAddress.city}  ${MapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                style={{ width: "100%", height: "100%" }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </InputsFolder>
        <InputsFolder title={t('Related details')}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <Label>{t('Note')}</Label>
              <TextArea
                {...register("notes")}
                rows={4}
                className={` resize-none border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular border`}
                placeholder={t('Write note')}
                error={errors?.notes}
              />
            </div>
            <ConditionalRender condition={isAssignLeadsActive === "Assign Leads" && hasPermissionToAssignLead}>
              <div>
                <div className="relative">
                  <Label text={t('Assigned to')} />
                  <Controller
                    name="assigned_to"
                    control={control}
                    rules={{ required: `${t('Assigned to is required')}` }}
                    render={({ field }) => (
                      <Creatable<any, false>
                        {...field}
                        placeholder={t('Assign to')}
                        options={
                          (
                            fetchedUsers as unknown as Array<{
                              name: string;
                              id: string;
                            }>
                          )?.map?.((user) => ({
                            label: user?.name,
                            value: user?.id,
                          })) ?? []
                        }
                        value={
                          field?.value
                            ? {
                                value: field?.value,
                                label:
                                  (
                                    fetchedUsers?.find?.(
                                      (user: any) => user.id === field.value
                                    ) as { name: string } | undefined
                                  )?.name || field.value,
                              }
                            : null
                        }
                        className="cursor-pointer font-inter-regular react-select-container"
                        classNamePrefix="react-select"
                        onChange={(newValue: any, actionMeta: any) => {
                          if (
                            actionMeta.action === "select-option" &&
                            newValue?.value
                          ) {
                            field.onChange(newValue.value);
                          }
                        }}
                        styles={customStyles}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
              </div>
            </ConditionalRender>
            {state.map((item: any) => {
              if (item?.data?.section === "Related details") {
                extraFields[item?.data?.field_name] = {
                  value: watch(item?.data?.field_name),
                  default_text: item?.data?.default_text,
                };
                return (
                  <Input
                    label={item?.data?.field_name}
                    type={item?.data?.field_type === "text" ? "text" : "number"}
                    required={item?.data?.required ? true : false}
                    {...register(item?.data?.field_name)}
                    error={errors?.[item?.data?.field_name]}
                  />
                );
              }
              return null;
            })}
          </div>
        </InputsFolder>

        {state.map((item: any) => {
          extraFields[item?.data?.field_name] = {
            value: watch(item?.data?.field_name),
            default_text: item?.data?.default_text,
          };
          return item?.data?.section !== "Personal information" &&
            item?.data?.section !== "Contact information" &&
            item?.data?.section !== "Address details" &&
            item?.data?.section !== "Related details" ? (
            <InputsFolder title={item?.data?.section}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <Input
                  label={item?.data?.field_name}
                  required={item?.data?.required ? true : false}
                  type={item?.data?.field_type === "text" ? "text" : "number"}
                  {...register(item?.data?.field_name)}
                  error={errors?.[item?.data?.field_name]}
                />
              </div>
            </InputsFolder>
          ) : null;
        })}
        <div className="flex justify-end gap-[26px]">
          <Button
            onClick={() => {
              setCurrentStep(2);
            }}
            type="button"
            className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme]`}
          >
            <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
            {t('Add Field')}
          </Button>
          <IconButton
            disabled={isLoading ? true : false}
            icon={<Icon iconType={IconType.PLUS_ICON} />}
          >
            {isLoading ? t('Creating...') : t('Create Lead')}
          </IconButton>
        </div>
      </Form>
    </>
  );
};

export default CreateLead;
