import { FC } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import TextArea from "components/atoms/text-area/TextArea";
import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import { Controller, useForm } from "react-hook-form";
import Input from "components/atoms/input/Input";
import Form from "components/atoms/form/Form";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { useGenerateTimeOptions } from "lib/hooks/shared/useGenerateTimeOptions";
import Label from "components/atoms/input/input-label/InputLabel";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import { useLeadToAppointment } from "lib/hooks/mutations/Leads/useLeadToAppointment";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

export interface INewCustomerProps {
    onConfirm: () => void;
    addressInfo: any;
    setCurrentStep: (step: number) => void;

    themeColors: {
        primary: string;
    };
}

const Appointment: FC<INewCustomerProps> = ({
    onConfirm,
    addressInfo,
    setCurrentStep,
    themeColors,
}) => {

    const { timeOptions } = useGenerateTimeOptions();

    const { mutateAsync, isLoading } =
        useLeadToAppointment();

    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            address: addressInfo.address,
            nr: addressInfo.nr,
            postal_code: addressInfo.postal_code,
            city: addressInfo.city,
            appointment_date: format(new Date().toLocaleDateString(), "yyyy-MM-dd"),
            time: "",
            appointment_type: "",
            note: "",
        }
    });

    const onSubmit = (formData: Record<string, any>): void => {

        const finalData = {
            lead_id: addressInfo.lead_id,
            date: formData.appointment_date,
            time: formData.time,
            appointment_type: formData.appointment_type,
            address: formData.address,
            nr: formData.nr,
            postal_code: formData.postal_code,
            city: formData.city,
            note: formData.note,

        }

        mutateAsync(finalData).then(() => {
            onConfirm()
            setCurrentStep(1)
        })

    };
    const {t} = useTranslation();
    const isDigital = watch("appointment_type") === "Online";

    return (
        <div className="mb-0 flex flex-col gap-7 w-full">
            <Button
                onClick={() => setCurrentStep(1)}
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#70DDE4] text-[22px]"
            >
                <Icon
                    iconType={IconType.BACK_BUTTON_MODAL}
                    className="!w-[30px] !h-[30px]"
                    color={themeColors.primary}
                />
                {t('Status : Appointment')}
            </Button>
            <Form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-8"
            >
                <div className="flex gap-5">
                    <div className="basis-1/2">
                        <div className="flex flex-col gap-5">
                            <Controller
                                control={control}
                                name="appointment_date"
                                rules={{ required: `${t('Appointment Date is required')}` }}
                                render={({ field: { onChange, value } }) => (
                                    <PrimaryDatePicker
                                        label={t('Appointment Date')}
                                        startDate={value}
                                        {...register("appointment_date")}
                                        setStartDate={(date: string) => onChange(date)}
                                        error={errors.appointment_date}
                                    />
                                )}
                            />
                            <div className="relative">
                                <Label text={t('Appointment Time')}/>
                                <Controller
                                    name="time"
                                    control={control}
                                    rules={{ required: `${t('Appointment Time is required')}` }}
                                    render={({ field }) => (
                                        <Creatable<any, false>
                                            {...field}
                                            placeholder={t('Choose appointment time')}
                                            options={timeOptions.map((language) => ({
                                                value: language.value,
                                                label: language.label,
                                            }))}
                                            value={
                                                field.value
                                                    ? { value: field.value, label: field.value }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}

                                        />
                                    )}
                                />
                                {errors.time?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-16px]  left-[2px]">
                                        <>{errors.time?.message}</>
                                    </div>
                                )}
                            </div>
                            <div className="relative">
                                <Label text={t('Appointment Type')} />
                                <Controller
                                    name="appointment_type"
                                    control={control}
                                    rules={{ required: `${t('Appointment Type is required')}` }}
                                    render={({ field }) => (
                                        <Creatable<any, false>
                                            {...field}
                                            placeholder={t('Choose appointment type')}
                                            options={[
                                                {
                                                    value: "Online",
                                                    label: `${t('Online')}`,
                                                },
                                                {
                                                    value: "On Site",
                                                    label: `${t('On Site')}`,
                                                },
                                            ]}
                                            value={
                                                field.value
                                                    ? { value: field.value, label: field.value }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                />
                                {errors.appointment_type?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-16px]  left-[2px]">
                                        <>{errors.appointment_type?.message}</>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="basis-1/2" style={{opacity: isDigital ? 0.5 : 1}}>
                        <div className="grid grid-cols-2 gap-5">
                            <Controller
                                control={control}
                                name="address"
                                rules={{ required: `${t('Address is required')}` }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder={t('Enter street')}
                                        label={t('Street')}
                                        classes={{ icon: "!bg-white right-[8px]" }}
                                        icon={IconType.LOCATION_ICON}
                                        {...register("address")}
                                        error={errors?.address}
                                        value={value}
                                        name="address"
                                        onChange={onChange}
                                        disabled={isDigital}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="nr"
                                rules={{ required: `${t('Nr is required')}` }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder={t('Enter Nr')}
                                        label={t('Nr')}
                                        {...register("nr")}
                                        error={errors?.nr}
                                        value={value}
                                        onChange={onChange}
                                        disabled={isDigital}

                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="postal_code"
                                rules={{ required: `${t('PLZ is required')}` }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder={t('Enter PLZ')}
                                        label={t('PLZ')}
                                        {...register("postal_code")}
                                        error={errors?.postal_code}
                                        name="postal_code"
                                        value={value}
                                        onChange={onChange}
                                        disabled={isDigital}

                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="city"
                                rules={{ required: `${t('City is required')}` }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder={t('Enter City')}
                                        label={t('City')}
                                        {...register("city")}
                                        error={errors?.city}
                                        value={value}
                                        name="city"
                                        onChange={onChange}
                                        disabled={isDigital}

                                    />
                                )}
                            />
                        </div>
                        <div className="pt-5">
                            <div className="invisible">
                                <Label text={t('Map')} />
                            </div>
                            <iframe
                                className="border-inputborder border rounded-[8px]"
                                title="map"
                                src={`https://maps.google.com/maps?q='+${addressInfo.postal_code} ${addressInfo.address} ${addressInfo.city}  ${addressInfo.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                                style={{ width: "100%", height: "100%" }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>


                <TextArea
                    label={t('Notes')}
                    placeholder={t('Write note ...')}
                    {...register("note")}
                    error={errors?.note}
                />

                <div className="flex justify-end gap-4 mt-[38px]">
                    <IconButton
                        secondary={true}
                        type="button"
                        onClick={() =>
                            reset()
                        }
                        className="w-full max-w-[150px] !border-[#70DDE4] !text-[#70DDE4]">
                        {t('Reset')}
                    </IconButton>
                    <IconButton
                        type="submit"
                        secondary={true}
                        className="w-full max-w-[150px] !border-[#70DDE4] bg-[#70DDE4] text-white"
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ? `${t('Updating')}...` : `${t('Confirm')}`}
                    </IconButton>
                </div>
            </Form>
        </div>
    );
};

export default Appointment;
