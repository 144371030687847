import Headline from "components/atoms/headline/Headline";
import { useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import CreatePartnerProcess from "./create-partner/CreatePartnerProcess";
import { usePartners } from "lib/hooks/queries/Partners/usePartners";
import PartnersList from "./partners-list/PartnersList";
import PartnersGridView from "./partners-grid-view/PartnersGridView";
import FilterDropDown from "./filter-dropdown/FilterDropDown";
import PartnersCard from "./partners-cards/PartnersCard";
import { useTranslation } from "react-i18next";
import { ISource } from "lib/types/sources.types";

import { usePermissions } from "lib/hooks/shared/usePermissions";
import { ActionButton, ToggleSwitchComp } from "lib/helpers/constants/columns/partnersHeaders";

export interface IProps {}

export interface IFilterProps {
  search?: string;
  status?: string;
  creation_date?: string;
}

const PartnersView: React.FC<IProps> = () => {
  const [filterData, setFilterData] = useState<IFilterProps>({
    search: "",
    status: "",
    creation_date: undefined,
  });

  const [activeView, setActiveView] = useState<string>("listView");
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [newPartnerCurrentStep, setNewPartnerCurrentStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage,
  } = usePartners(filterData);

  const { t } = useTranslation();


  const partnersTableHeaders = [
    {
      Header: t("Creation Date"),
      accessor: (row: ISource) =>
        new Date(row?.created_at).toLocaleDateString('de') || "Not available",
      id: 1,
      isChecked: true,
    },
    {
      id: 2,
      Header: t("Partner Name"),
      accessor: (row: ISource) =>
        row?.name || row?.logo ? (
          <div className="flex flex-row items-center gap-2 ">
            <ConditionalRender condition={row?.logo?.length > 0}>
              <img
                loading="lazy"
                className="object-fit rounded-full w-[36px] h-[36px]"
                src={`https://devmainhub.insusales.com/api/partners/logo/${row.logo}`}
                alt="Logo"
              />
            </ConditionalRender>
            <div className="">{row.name}</div>
          </div>
        ) : (
          t("Not available")
        ),
      isChecked: true,
    },
    {
      Header: t("Email"),
      accessor: (row: any) => row?.email || t("Not available"),
      id: 3,
      isChecked: true,
    },
    {
      Header: t("Phone"),
      accessor: (row: any) => row?.phone || t("Not available"),
      id: 4,
      isChecked: true,
    },
    {
      Header: t("Leads"),
      accessor: (row: any) => t("Not available"),
      id: 5,
      isChecked: true,
    },
    {
      Header: t("Appointements"),
      accessor: (row: any) => t("Not available"),
      id: 6,
      isChecked: true,
    },
    {
      Header: t("Status"),
      accessor: (row: ISource) => row.status === "1",
      id: 7,
      Cell: ({ row }: any) => (
        <ToggleSwitchComp
          id={row.original.id}
          value={row.original.status}
          original={row.original}
        />
      ),
      isChecked: true,
    },
    {
      Header: "",
      accessor: (row: ISource) => row.status === "1",
      id: 8,
      Cell: ({ row }: any) => (
        <ActionButton
          id={row.original.id}
          value={row.original.status}
          original={row.original}
        />
      ),
      isChecked: true,
    },
  ];

  const [cardsOrder, setCardsOrder] = useState(partnersTableHeaders);

  return (
    <>
      <Headline title={t("Partners")} />
      <PartnersCard />
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`${t("Partners")}`}
            />
          </div>
          <ConditionalRender condition={usePermissions(['manage_partners_create'])}>
          <IconButton
            onClick={() => setIsOpen(true)}
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            >
            {t("New Partner")}
          </IconButton>
          </ConditionalRender>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search")}
              value={filterData?.search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  search: e.target.value,
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title={t("Configure Table")}
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title="Filter"
                    className="sm:!w-[350px]"
                  >
                    <FilterDropDown
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={activeView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={activeView === "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.partners?.data?.length > 0}
              fallback={<EmptyEntity name="Partners" />}
            >
              <PartnersGridView data={data?.pages?.[0]?.partners?.data} />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
        <ConditionalRender condition={activeView !== "kundenView"}>
          <div className="relative">
            {isLoading ? (
              <div className="py-10">

              <LoadingScreen />
              </div>
            ) : (
              <NoDataBoundary
                condition={data?.pages?.[0]?.partners?.data?.length > 0}
                fallback={<EmptyEntity name="Partners" />}
              >
                <PartnersList
                  key={currentPage}
                  data={data?.pages?.[0]?.partners?.data ?? []}
                  dataCols={cardsOrder}
                />
              </NoDataBoundary>
            )}
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <PaginationControls
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={(value: number) =>
          handleItemsPerPageChange(value)
        }
        from={data?.pages[0]?.partners?.from}
        to={data?.pages[0]?.partners?.to}
        total={data?.pages[0]?.partners?.total}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
      <ConditionalRender condition={isOpen}>
        <CreatePartnerProcess
          setHocCurrentStep={setNewPartnerCurrentStep}
          hocCurrentStep={newPartnerCurrentStep}
          onConfirm={() => setIsOpen(false)}
          setCurrentStep={setNewPartnerCurrentStep}
          currentStep={newPartnerCurrentStep}
          hocisOpen={isOpen}
          hocToggleModal={() => setIsOpen(false)}
          hocTitle={
            newPartnerCurrentStep === 1
              ? "Create New Partner"
              : newPartnerCurrentStep === 2
              ? ""
              : ""
          }
          hocClasses={{
            titleClassName: "!pb-0",
            modalClassName: `${`sm:max-w-[551px] sm:w-full w-full ${
              newPartnerCurrentStep === 1
                ? "md:min-w-[551px] md-max-w-full lg:max-w-[551px] w-full"
                : newPartnerCurrentStep === 2
                ? "sm:max-w-[551px]  md:min-w-[551px] lg:w-[551px]"
                : ""
            }`}`,
          }}
        />
      </ConditionalRender>
    </>
  );
};
export default PartnersView;
