
import IconButton from 'components/atoms/button/IconButton';
import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';


export interface IDeleteModalProps {
    onConfirm: () => void;
    cancelFunction?: (arg: number) => void;
    data?: {
        id: string,
        name: string
    };
    isLoading?: boolean;
    deleteFunction: (id: string | undefined) => Promise<void>;
}

const DeleteModal: FC<IDeleteModalProps> = ({ onConfirm, data, isLoading, deleteFunction, cancelFunction }) => {

    const { t } = useTranslation();
  
    return (
        <>
            <div className="mb-0 flex flex-col items-center gap-5">
                <Icon iconType={IconType.DELETE_WARNING_ICON} />
                <div className='text-xl text-primaryTextColor font-inter-semibold text-center'>
                    {t('Are you sure you want to delete ')}{data?.name}
                </div>
            </div>
            <div className="flex flex-row gap-5 pt-10">
                <IconButton secondary className="!w-full" type='button' onClick={() => { cancelFunction ? cancelFunction(1) : onConfirm() }} disabled={isLoading}>
                    {t('Cancel')}
                </IconButton>
                <IconButton className="!w-full" type='button' disabled={isLoading}
                    onClick={() => {
                        deleteFunction(data?.id).then(() => { onConfirm() })
                    }}>
                    {t('Delete')}
                </IconButton>
            </div>
        </ >
    )
}

export default DeleteModal