import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import Card from "components/molecules/card/Card";
import { IPendingsListCardProps } from "components/molecules/card/pendings-list-card/PendingsListCard";
import CreatePending from "components/organisms/pendings/create-pendings/CreatePending";
import CustomersTable from "components/organisms/pendings/customers-table/CustomersTable";
import PendingCards from "components/organisms/pendings/pendings-cards/PendingCards";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useCustomerPendings } from "lib/hooks/queries/Pendings/useCustomerPendings";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Pendings = () => {
  const { data: customersData , isLoading} =
    useCustomerPendings();

  const [isShowedPendingList, setIsShowedPendingList] = useState<boolean>(false);
  const {t} = useTranslation();

  const [isCreatePendingModalOpen, setIsCreatePendingModalOpen] =useState<boolean>(false);
  
  const [currentPending, setCurrentPending] = useState<number>(1);useState<boolean>(false);

  const cardsListData = [
    usePermissions(['pending_hr_view_view','pending_hr_view_view_own']) ? {
      id: "1",
      label: t("HR Pendings"),
      icon: IconType.HR_ICON,
      color: "#FFE39B"
    } : null as any,
    usePermissions(['pending_customer_view_view','pending_customer_view_view_own']) ? {
      id: "2",
      label: t("Customer Pendings"),
      icon: IconType.CUSTOMERS_ICON,
      color: "#00D097",
      data: customersData?.pages?.[0]?.data?.data ?? [],
      type: "customer"
    } : null as any,
    usePermissions(['pending_myown_view_view']) ? {
      id: "3",
      label: t("My own Pendings"),
      icon: IconType.PENDINGS_ICON,
      color: "#15C0E6"
    } : null as any,
    usePermissions(['pending_other_view_view','pending_other_view_view_own']) ? {
      id: "4",
      label: t("Other Pendings"),
      icon: IconType.PENDINGS_ICON,
      color: "#6D5DD3"
    } : null as any
  ];
  return (
    <>
      <div className="flex items-center justify-between">
        <Headline title="Pendings" />
        <ConditionalRender condition={!isShowedPendingList}>
          <div className="flex items-center gap-[16px]">
            <IconButton
              icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} color="white" />}
            >
              {t('Upload Pending')}
            </IconButton>
            <IconButton
              onClick={() => setIsCreatePendingModalOpen(true)}
              icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
            >
              {t('New Pending')}
            </IconButton>
          </div>
        </ConditionalRender>
      </div>
      <ConditionalRender condition={!isShowedPendingList}>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-5">
          {cardsListData.map((item) => {
            if(item === null) return null
            return (
              <Card<IPendingsListCardProps>
                key={item.id}
                {...item}
                props={{
                  label: item.label,
                  icon: item.icon,
                  color: item.color,
                  setIsShowedPendingList: setIsShowedPendingList,
                  data: item?.data,
                  type: item?.type,
                  isLoadingData: isLoading
                }}
                cardType={CardType.PENDINGS_LIST_CARD}
              />
            );
          })}
        </div>
      </ConditionalRender>
      <ConditionalRender condition={isShowedPendingList}>
        <PendingCards />
        <CustomersTable />
      </ConditionalRender>
      <ConditionalRender condition={currentPending === 1}>
        <CreatePending
          currentPending={currentPending}
          hocClasses={{
            modalClassName:
              currentPending === 1 ? "w-[500px] md:w-[500px] lg:w-[551px]" : ""
          }}
          hocCurrentStep={currentPending}
          hocToggleModal={() =>
            setIsCreatePendingModalOpen(!isCreatePendingModalOpen)
          }
          hocisOpen={isCreatePendingModalOpen}
          setHocCurrentStep={setCurrentPending}
          clearData={true}
          hocTitle="Create New Pending"
        />
      </ConditionalRender>
    </>
  );
};

export default Pendings;
