import { useForm, UseFormProps } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const AppointmentSchema = YUP.object().shape({
  first_name: YUP.string().required("Please enter first name"),
  last_name: YUP.string().required("Please enter last name"),
  phone_number: YUP.string().required("Please enter phone"),
  nationality: YUP.string().optional().nullable(),
  birthdate: YUP.string().required("Please enter date of birth"),
  date: YUP.string().required("Please enter Appointment Date"),
  time: YUP.string().required("Please enter Appointment Time"),
  source: YUP.string().optional().nullable(),
  persons: YUP.string().required("Please enter number of Persons in household"),
  language: YUP.string().required("Please enter language"),
  current_health_insurance: YUP.string().optional().nullable(),
  insurance_type: YUP.string().optional().nullable(),
  is_digital: YUP.string().required("Please select Appointment type"),
  email: YUP.string().optional().nullable(),
  address: YUP.string().required("Please enter street"),
  nr: YUP.number()
    .transform((value, originalValue) => {
      const numericValue = Number(originalValue);
      return isNaN(numericValue) ? undefined : numericValue;
    })
    .min(1, "At least 1 character")
    .required("Please enter house number"),
  postal_code: YUP.number()
    .transform((value: any, originalValue: string) => {
      return originalValue === "" || isNaN(Number(originalValue))
        ? undefined
        : value;
    })
    .min(1)
    .required("Please enter postal code"),
  city: YUP.string().required("Please enter city"),
  note: YUP.string().optional().nullable(),
});

export function useAppointmentForm(options?: UseFormProps<any>) {
  return useForm<any | any>({
    mode: "onSubmit",
    ...options,
    resolver: yupResolver(AppointmentSchema),
  });
}

export type AppointmentSchemaType = ReturnType<typeof useAppointmentForm>;
