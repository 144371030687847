import { FC, useEffect, useState } from 'react';
import Button from 'components/atoms/button/Button';
import IconButton from 'components/atoms/button/IconButton';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import Icon from 'components/atoms/icon/Icon';
import TextArea from 'components/atoms/text-area/TextArea';
import Picker from "@emoji-mart/react";
import { IconType } from 'lib/helpers/constants/iconTypes';
import { formatDistanceToNow } from "date-fns";
import Label from 'components/atoms/input/input-label/InputLabel';
import { useTranslation } from 'react-i18next';
import { usePermissions } from 'lib/hooks/shared/usePermissions';

interface ICommentProps {
    data: any;
    handleSend: () => void;
    setImageBlob: (image: Blob | null) => void;
    comment: string;
    setComment: (comment: string) => void;
    imagePreview: any;
    setImagePreview: (image: any) => void;
    isLoading?: boolean;
    label?: string;
    pathForImg?: string;
}


const Comments: FC<ICommentProps> = ({
    data,
    handleSend,
    setImageBlob,
    comment, setComment,
    imagePreview, setImagePreview,
    isLoading,
    label,
    pathForImg = 'costumers/pendings/show-comment-img/'
}) => {


    const [textareaHeight, setTextareaHeight] = useState("auto");

    useEffect(() => {
        if (imagePreview) {
            setTextareaHeight("250px");
        } else {
            setTextareaHeight("auto");
        }
    }, [imagePreview]);

    const [isHover, setIsHover] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);



    const [showAttachmentByIndex, setShowAttachmentByIndex] = useState<any>();

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file));
            setImageBlob(file);
        }
    };

    const handleEmojiSelect = (emoji: any) => {
        setComment(((prevComment: string) => prevComment + emoji.native) as unknown as string);
        setShowEmojiPicker(false);
    };

    const handleRemoveImage = () => {
        setImagePreview(null);
        setImageBlob(null);
    };

    const { t } = useTranslation();

    return (
        <>
            {label && (

                <Label>{label}</Label>
            )}
            <div className="border-[#D0D5DD] bg-white border-[1px] rounded-[8px] relative">
                <div
                    className="relative"
                    style={{ height: textareaHeight }}
                    onMouseLeave={() => setIsHover(false)}
                >
                    <TextArea
                        onChange={(e) => setComment(e.target.value)}
                        className="!border-none bg-transparent"
                        value={comment}
                        style={{ height: "100%", resize: "none" }}
                    />
                    <div className="absolute flex items-center gap-[6px] bottom-[10px] left-[20px]">
                        <div onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                            <Icon iconType={IconType.EMOJI_ICON} />
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                            id="image-upload"
                        />
                        <label htmlFor="image-upload">
                            <Icon iconType={IconType.ATTACHMENT_ICON} />
                        </label>
                    </div>
                    <div
                        className={`${textareaHeight ? "top-[13rem]" : ""
                            } absolute z-[999]`}
                    >
                        {showEmojiPicker && (
                            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                        )}
                    </div>
                    <ConditionalRender condition={usePermissions(['pending_customer_view_edit'])}>
                        <IconButton
                            type="button"
                            onClick={handleSend}
                            disabled={comment.length === 0 || isLoading}
                            className="w-[100px] h-[40px] flex items-center justify-center absolute bottom-[10px] right-[20px]"
                        >
                            {isLoading ? t("Sending...") : t("Send")}
                        </IconButton>
                    </ConditionalRender>
                    {imagePreview && (
                        <div className="absolute bottom-[60px] left-[20px]">
                            <img
                                src={imagePreview}
                                onMouseEnter={() => setIsHover(true)}
                                alt="Preview"
                                className="w-[100px] h-[100px] object-cover rounded-[8px]"
                            />
                            {isHover && (
                                <Button
                                    type="button"
                                    onClick={handleRemoveImage}
                                    className="absolute top-[3px] right-[5px]"
                                >
                                    <Icon
                                        iconType={IconType.CLOSE_ICON}
                                        className="w-[15px] h-[15px] p-[2px] bg-white rounded-full"
                                    />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ConditionalRender
                condition={data?.length > 0}
            >
                <div className="mt-[24px]">
                    {data?.map((item: any, index: any) => (
                        <div
                            key={index}
                            className="flex flex-col gap-[12px] mb-[24px]"
                        >
                            <div className="flex items-center gap-[10px]">
                                <img
                                    alt=''
                                    className="w-[30px] h-[30px] rounded-full object-cover"
                                    src="https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                                />
                                <p className="font-inter-medium text-[#282D46] text-[15px]">
                                    {item?.user_id}
                                </p>
                            </div>
                            <div className="pl-[5px]">
                                <p className="text-[#282D46] capitalize text-[14px] font-inter-regular">
                                    {item.comment || item.note}
                                </p>
                                {item.image && showAttachmentByIndex === item?.image ? (
                                    <img
                                        alt=""
                                        src={`https://devmainhub.insusales.com/api/${pathForImg + item?.image}`}
                                        className="w-[100px] h-[100px] mt-[12px] object-cover cursor-pointer"
                                        onClick={() => {
                                            window.open(
                                                `https://devmainhub.insusales.com/api/${pathForImg + item?.image}`,
                                                "_blank"
                                            );
                                        }}
                                    />
                                ) : null}
                                <div className="mt-[12px] flex items-center gap-[12px]">
                                    <p className="text-[#7D8592] text-[12px] font-inter-regular">
                                        {formatDistanceToNow(new Date(item.created_at), {
                                            addSuffix: true,
                                        })}
                                    </p>
                                    <Button
                                        type="button"
                                        disabled={!item.image}
                                        onClick={() => setShowAttachmentByIndex(item?.image)}
                                        className="text-[#7D8592] cursor-pointer text-[12px] font-inter-regular flex items-center gap-[6px]"
                                    >
                                        <Icon
                                            iconType={IconType.ATTACHMENT_ICON}
                                            className="w-[14px] h-[14px]"
                                        />{" "}
                                        {item?.image ? "1" : "0"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </ConditionalRender>
        </>
    )
}

export default Comments