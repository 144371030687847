import { FC, useLayoutEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import TextArea from "components/atoms/text-area/TextArea";
import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import { PlusIcon } from "components/atoms/icon/export";
import { Controller, FieldError, useFieldArray } from "react-hook-form";
import Input from "components/atoms/input/Input";
import Checkbox from "components/atoms/checkbox/Checkbox";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import Form from "components/atoms/form/Form";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import { useLeadToNewCustomer } from "lib/hooks/mutations/Leads/useLeadToNewCustomer";
import { useTranslation } from "react-i18next";

type ErrorsType = {
    persons?: {
        [key: number]: {
            first_name?: FieldError;
            last_name?: FieldError;
        };
    };
};

export interface INewCustomerProps<T> {
    onConfirm: () => void;
    data?: T;
    setCurrentStep: (step: number) => void;
    currentStep: number;
    setStoreData?: any;
    formState?: any;
    handleSubmit?: any;
    register?: any;
    control?: any;
    reset?: any;
    trigger?: any;
    getValues?: any;
    clearErrors?: any;
    LeadInfo?: any
    themeColors: {
        primary: string;
    };
}

const NewCustomer: FC<INewCustomerProps<any>> = ({
    onConfirm,
    data,
    setCurrentStep,
    setStoreData,
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
    themeColors,
    LeadInfo,
}) => {
    const { mutateAsync, isLoading: isLoadingFeedback } =
        useLeadToNewCustomer();

    const { data: partnersData, isLoading } = usePartners();

    const [activeTab, setActiveTab] = useState<number>(0);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "persons",
    });

    const validateCheckboxes = (value: any) => {
        return (
            Object.values(value).some((v: any) => v?.checked) ||
            "At least one checkbox must be selected"
        );
    };

    useLayoutEffect(() => {
        
        if (LeadInfo.persons) {
            reset();
            for (let i = 0; i < LeadInfo.persons; i++) {
                if(i === 0) { 

                    append({first_name: LeadInfo.first_name, last_name: LeadInfo.last_name, birthdate: LeadInfo.birthdate});
                }
                else {

                    append({});
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    const {t} = useTranslation();

    const handleTabClick = async (index: number) => {
        const currentPerson = getValues(`persons.${activeTab}`);

        if (currentPerson.first_name || currentPerson.last_name) {
            const result = await trigger(`persons.${activeTab}`);
            if (!result) {
                return;
            }
        } else {

            clearErrors(`persons.${activeTab}`);
        }

        setActiveTab(index);
    };

    const onSubmit = (formData: Record<string, any>): void => {

        const transformedData = {
            lead_id: LeadInfo.id,
            note: formData.note,
            persons: fields
                .map((_, index) => {
                    const products = optionsByCheckbox
                        .map(({ checkboxValue, customOptions }) => {
                            const checkboxChecked =
                                formData?.persons?.[index]?.[checkboxValue]?.checked;
                            const selectedValue =
                                formData?.persons?.[index]?.[checkboxValue]?.selected;
                            if (checkboxChecked) {
                                let company;
                                if (selectedValue) {
                                    const customOption = customOptions.find(
                                        (option: { value: any }) => option.value === selectedValue
                                    );
                                    company = customOption ? customOption.label : null;
                                } else {
                                    company =
                                        customOptions.length > 0 ? customOptions[0].label : null;
                                }

                                return {
                                    name: checkboxValue,
                                    company: company,
                                };
                            }
                            return null;
                        })
                        .filter((product) => product !== null);

                    const { last_name, first_name, birthdate } = formData?.persons[index];

                    return {
                        last_name,
                        first_name,
                        birthdate,
                        products: products,
                    };
                })
                .filter(
                    (person) =>
                        person.last_name ||
                        person.first_name ||
                        person.birthdate ||
                        person.products.length > 0
                ),
        };

        mutateAsync(transformedData)
            .then(() => {
                onConfirm()
                setCurrentStep(1)
            })
            .catch(() => { });
    };


    const optionsByCheckbox = [
        {
            checkboxValue: `${t('KVG + VVG')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('KVG')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('VVG')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('life')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('lawprotection')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('business')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
        {
            checkboxValue: `${t('auto')}`,
            customOptions: partnersData?.partners?.map?.((partner: any) => ({
                label: partner?.name,
                value: partner?.name,
            })),
        },
    ];

    if (isLoading) {
        <LoadingScreen />;
    }

    return (
        <Fragment>
            <>
                <div className="mb-0 flex flex-col gap-7 w-full">
                    <Button
                        onClick={() => setCurrentStep(1)}
                        type="button"
                        className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#2DE28B] text-[22px]"
                    >
                        <Icon
                            iconType={IconType.BACK_BUTTON_MODAL}
                            className="!w-[30px] !h-[30px]"
                            color={themeColors.primary}
                        />
                        {t('Status : Won')}
                    </Button>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-8"
                    >
                        <div className="flex flex-col gap-8">
                            <div>
                                <div className="overflow-auto horizontalOverflowScrollStyle p-0">
                                    <div className="flex flex-col">
                                        <div className="flex flex-row">
                                            {fields.map((field, index) => (
                                                <>
                                                    <div
                                                        key={`field-key-${field.id}`}
                                                        className={`min-w-[174px] font-inter-bold  whitespace-nowrap !border-b-0 ${index === 0 && "rounded-tl-[8px]"
                                                            } ${index === 0 && fields.length > 1 ? "rounded-tl-[26px] " : ""
                                                            } ${activeTab === index
                                                                ? "bg-[#2DE28B] text-white"
                                                                : "border-[#D0D5DD] border-[1px] text-[#7D8592] rounded-tr-none"
                                                            } ${index === fields.length - 1 ? "!rounded-tr-[26px]" : ""
                                                            } flex items-center justify-between`}
                                                    >
                                                        <Button
                                                            type="button"
                                                            className="flex-grow"
                                                            onClick={() => handleTabClick(index)}
                                                        >
                                                            {t('Person')} {index + 1}
                                                        </Button>
                                                        {data?.info?.persons <= index ?
                                                            <Button type="button" onClick={() => remove(index)} className={`mr-[12px] ${activeTab === index ? 'hidden' : 'block'}`}>
                                                                <Icon iconType={IconType.CLOSE_X_SM_ICON} color="#000000" />
                                                            </Button>
                                                            : ''}

                                                    </div>
                                                </>
                                            ))}
                                            <Button
                                                onClick={() => {
                                                    append({});
                                                }}
                                                type="button"
                                                className="flex cursor-pointer  whitespace-nowrap flex-row items-center gap-3  py-[8px] pl-[14px]  2xl:px-[35px] font-inter-medium text-[#7D8592] leading-[24px] text-[16px]"
                                            >
                                                <PlusIcon color="#7D8592" />
                                                {t('Add Person')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-5">
                                        {fields?.map?.((person, index) => (
                                            <Fragment key={`person-key-${person.id}`}>
                                                {index === activeTab ? (
                                                    <Fragment>
                                                        <div className="border-[1px] border-[#D0D5DD] rounded-r-[30px] rounded-b-[30px] p-[35px] grid md:grid-cols-2 gap-[30px] 2xl:gap-[40px]">
                                                            <div className="flex flex-col gap-5">
                                                                <Input
                                                                    label={`${t('First Name Person')} ${index + 1}`}
                                                                    {...register(`persons.${index}.first_name`, {
                                                                        required: t('First Name is required'),
                                                                    })}
                                                                    error={
                                                                        (formState.errors as ErrorsType)?.persons?.[
                                                                            index
                                                                        ]?.first_name
                                                                    }
                                                                />
                                                                <Input
                                                                    label={`${t('Last Name Person')} ${index + 1}`}
                                                                    {...register(`persons.${index}.last_name`, {
                                                                        required: t('Last Name is required'),
                                                                    })}
                                                                    error={
                                                                        (formState.errors as ErrorsType)?.persons?.[
                                                                            index
                                                                        ]?.last_name
                                                                    }
                                                                />
                                                                <Controller
                                                                    control={control}
                                                                    name={`persons.${index}.birthdate`}
                                                                    rules={{
                                                                        required: t('Date Of Birth is required'),
                                                                    }}
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <PrimaryDatePicker
                                                                            label={`${t('Date Of Birth Person')} ${index + 1}`}
                                                                            startDate={value}
                                                                            setStartDate={(date: string) =>
                                                                                onChange(date)
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="mt-[10px] flex flex-col gap-[10px]">
                                                                {optionsByCheckbox?.map?.(
                                                                    ({ checkboxValue, customOptions }) => (
                                                                        <div
                                                                            key={`options-key-${checkboxValue}`}
                                                                            className="flex items-center w-full gap-[8px] 2xl:gap-5"
                                                                        >
                                                                            <div className="flex items-center gap-3 w-[75%]">
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`persons.${index}`}
                                                                                    rules={{
                                                                                        validate: validateCheckboxes,
                                                                                    }}
                                                                                    render={({ field }) => (
                                                                                        <div className="flex items-center gap-1 2xl:gap-3 w-[75%]">
                                                                                            <Checkbox
                                                                                                className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border transition-all checked:border-[#2DE28B] checked:bg-[#2DE28B] hover:before:opacity-10"
                                                                                                value={checkboxValue}
                                                                                                {...register(
                                                                                                    `persons.${index}.${checkboxValue}.checked`,
                                                                                                    {
                                                                                                        onChange: () =>
                                                                                                            trigger(
                                                                                                                `persons.${index}`
                                                                                                            ),
                                                                                                    }
                                                                                                )}
                                                                                            />
                                                                                            <h1 className="capitalize text-[#282D46] text-[14px] whitespace-nowrap font-inter-regular">
                                                                                                {checkboxValue}
                                                                                            </h1>
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <Controller
                                                                                control={control}
                                                                                name={`persons.${index}.${checkboxValue}.selected`}
                                                                                render={({ field }) => (
                                                                                    <Creatable<any, false>
                                                                                        {...field}
                                                                                        placeholder={t('Choose Company')}
                                                                                        options={customOptions}
                                                                                        value={
                                                                                            field.value
                                                                                                ? { value: field.value, label: field.value }
                                                                                                : null
                                                                                        }
                                                                                        className="cursor-pointer !w-full font-inter-regular react-select-container"
                                                                                        classNamePrefix="react-select !w-full"
                                                                                        onChange={(newValue: any, actionMeta: any) => {
                                                                                            if (actionMeta.action === "select-option" && newValue?.value) {
                                                                                                field.onChange(newValue.value);
                                                                                            }
                                                                                        }}
                                                                                        styles={customStyles}
                                                                                        isValidNewOption={() => false}
                                                                                        menuPosition={"fixed"}
                                                                                        menuPortalTarget={document.body}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                                {formState.persons &&
                                                                    (formState.persons as any)[index] && (
                                                                        <pre
                                                                            className={`font-inter-regular text-xs text-red-600 w-max`}
                                                                        >
                                                                            {
                                                                                (formState.persons as any)[index]
                                                                                    .message
                                                                            }
                                                                        </pre>
                                                                    )}
                                                                <IconButton
                                                                    onClick={async () => {
                                                                        const currentPerson = getValues(
                                                                            `persons.${activeTab}`
                                                                        );

                                                                        if (
                                                                            currentPerson.first_name ||
                                                                            currentPerson.last_name
                                                                        ) {
                                                                            const result = await trigger(
                                                                                `persons.${activeTab}`
                                                                            );
                                                                            if (!result) {
                                                                                return;
                                                                            }
                                                                        }

                                                                        if (activeTab < fields.length - 1) {
                                                                            setActiveTab((prev) => prev + 1);
                                                                        }
                                                                    }}
                                                                    type="button"
                                                                    icon={
                                                                        <Icon iconType={IconType.APPROVED_ICON} />
                                                                    }
                                                                    secondary={true}
                                                                    className="w-full max-w-[190px] !border-[--theme] bg-[--theme] text-white mt-[38px] ml-auto"
                                                                >
                                                                    {t('Next Person')}
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ) : null}
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TextArea
                            label={t('Notes')}
                            placeholder={t('Write note ...')}
                            {...register("note")}
                            error={formState?.note}
                        />

                        <div className="flex justify-end gap-4 mt-[38px]">
                            <IconButton
                                secondary={true}
                                type="button"
                                onClick={() =>
                                    reset({
                                        note: "",
                                        persons: fields?.map(() => ({
                                            last_name: "",
                                            first_name: "",
                                            birthdate: "",
                                            "KVG + VVG": "",
                                            KVG: "",
                                            VVG: "",
                                            life: "",
                                            lawprotection: "",
                                            business: "",
                                            auto: "",
                                        })),
                                    })
                                }
                                className="w-full max-w-[150px] !border-[#2DE28B] !text-[#2DE28B]">
                                {t('Reset')}
                            </IconButton>
                            <IconButton
                                type="submit"
                                secondary={true}
                                className="w-full max-w-[150px] !border-[#2DE28B] bg-[#2DE28B] text-white"
                                disabled={isLoadingFeedback ? true : false}
                            >
                                {isLoadingFeedback ? t('Updating...') : t('Confirm')}
                            </IconButton>
                        </div>
                    </Form>
                </div>
            </>
        </Fragment>
    );
};

export default NewCustomer;
