import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddNewCost from './modals/AddNewCost'
import AddNewTurnover from './modals/AddNewTurnover'
import { useComparison } from 'lib/hooks/queries/Finances/useComparison'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import CreatableSelect from 'react-select/creatable'
import { months } from 'lib/helpers/constants/months'
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles'
import { usePermissions } from 'lib/hooks/shared/usePermissions'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'


type ComparisonDataProps = {
  cost_1: string,
  cost_2: string,
  cost_3: string,
  total: number
}

const FinanceComparisonView = () => {

  const { t } = useTranslation()

  const [FilterData, setFilterData] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

  const hasPermissionToCreateComparison = usePermissions(['planned_actual_soll_ist_create']);

  const [IsAddCostModalOpen, setIsAddCostModalOpen] = React.useState<string | undefined>(undefined)
  const [IsAddTurnoverModalOpen, setIsAddTurnoverModalOpen] = React.useState<string | undefined>(undefined)

  const { data, isLoading } = useComparison(FilterData)

  const SollCosts = useMemo(() =>

    data?.find(data => data.head_section === 'Soll' && data.section === 'Cost'

    ), [data]) as unknown as ComparisonDataProps

  const SollTurnOver = useMemo(() =>

    data?.find(data => data.head_section === 'Soll' && data.section === 'turnover_soll'

    ), [data]) as unknown as ComparisonDataProps

  const ISTCosts = useMemo(() =>

    data?.find(data => data.head_section === 'Ist' && data.section === 'Cost'

    ), [data]) as unknown as ComparisonDataProps

  const ISTTurnOver = useMemo(() =>

    data?.find(data => data.head_section === 'Ist' && data.section === 'turnover_ist'

    ), [data]) as unknown as ComparisonDataProps

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div>
      <div className='flex gap-4 items-center mt-[46px] mb-[33px]'>
        <Headline title={t("Planned vs. Actual Comparison (SOLL/IST)")} className='!mb-0 !mt-0 flex-1' />

        <CreatableSelect<any, false>

          options={[
            { value: 2023, label: "2023" },
            { value: 2024, label: "2024" },
            { value: 2025, label: "2025" },
            { value: 2026, label: "2026" },
          ]}
          value={
            FilterData.year
              ? {
                value: FilterData.year,
                label: FilterData.year,
              }
              : null
          }
          className="cursor-pointer font-inter-regular react-select-container"
          classNamePrefix="react-select"
          onChange={(selectedOption) => {
            const selectedValue = selectedOption
              ? selectedOption.value
              : null;
            setFilterData({ ...FilterData, year: selectedValue });
          }}
          styles={customStylesLightBorder}
          isValidNewOption={() => false}
          menuPosition={"fixed"}
          menuPortalTarget={document.body}
        />

        <CreatableSelect<any, false>
          options={
            months.map((month) => ({
              value: month.value,
              label: t(month.label),
            }))
          }
          value={
            FilterData.month
              ? {
                value: FilterData.month,
                label: months[FilterData.month - 1].label,
              }
              : null
          }
          className="cursor-pointer font-inter-regular react-select-container"
          classNamePrefix="react-select"
          onChange={(selectedOption) => {
            const selectedValue = selectedOption
              ? selectedOption.value
              : null;
            setFilterData({ ...FilterData, month: selectedValue });
          }}
          styles={customStylesLightBorder}
          isValidNewOption={() => false}
          menuPosition={"fixed"}
          menuPortalTarget={document.body}
        />
      </div>
      <div className='flex gap-7'>
        <DashboardBoxLayout className='flex-1 flex flex-col gap-5'>
          <DashboardBoxTitle title='Planned (SOLL) Comparison' className=' !text-[--theme]' />

          <div className='p-4 border border-[#F2F4F7] rounded-[20px]'>
            <div className='flex items-center gap-3 mb-5'>

              <div className="flex-1">
                <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                  {t('Costs')}
                </span>
              </div>
              <ConditionalRender condition={hasPermissionToCreateComparison}>
                <div className="flex-none">
                  <div
                    className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                    onClick={() => { setIsAddCostModalOpen('Soll') }}
                    >
                    <Icon iconType={IconType.PLUS_ICON} color='#fff' />
                  </div>
                </div>
              </ConditionalRender>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Direct Costs')}</span>
                <span className='!font-inter-semibold'>{SollCosts?.cost_1 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Indirect Costs')}</span>
                <span className='!font-inter-semibold'>{SollCosts?.cost_2 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Employee cosmissions')}</span>
                <span className='!font-inter-semibold'>{SollCosts?.cost_3 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span className='!font-inter-bold'>
                  {SollCosts?.total || 0} CHF
                </span>
              </div>
            </div>
          </div>
          <div className='p-4 border border-[#F2F4F7] rounded-[20px]'>
            <div className='flex items-center gap-3 mb-5'>

              <div className="flex-1">
                <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                  {t('Target Turnover (SOLL)')}
                </span>
              </div>
              <ConditionalRender condition={hasPermissionToCreateComparison}>
              <div className="flex-none">
                <div
                  className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                  onClick={() => { setIsAddTurnoverModalOpen('Soll') }}
                >
                  <Icon iconType={IconType.PLUS_ICON} color='#fff' />
                </div>
              </div>
              </ConditionalRender>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Health insurance revenue')}</span>
                <span className='!font-inter-semibold'>{SollTurnOver?.cost_1 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Life insurance revenue')}</span>
                <span className='!font-inter-semibold'>{SollTurnOver?.cost_2 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Overheard revenue')}</span>
                <span className='!font-inter-semibold'>{SollTurnOver?.cost_3 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span className='!font-inter-bold'>
                  {SollTurnOver?.total || 0} CHF
                </span>
              </div>
            </div>
          </div>
          <div className={`p-4 border border-[#F2F4F7] rounded-[20px]
          ${SollTurnOver?.total - SollCosts?.total > 0 ? '!border-[#2CC95B]' : '!border-[#F45B69]'}
          `}>
            <div className='flex items-center gap-3 mb-5'>
              <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                {t("Success Calculation Plan")}
              </span>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Total costs')}</span>
                <span className='!font-inter-semibold'>{SollCosts?.total || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Total revenue')}</span>
                <span className='!font-inter-semibold'>{SollTurnOver?.total || 0} CHF</span>
              </div>

              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span
                  className={`!font-inter-semibold ${SollTurnOver?.total - SollCosts?.total > 0 ? 'text-[#2CC95B]' : 'text-[#F45B69]'}`}
                >
                  {(SollTurnOver?.total - SollCosts?.total) || 0} CHF
                </span>
              </div>
            </div>
          </div>



        </DashboardBoxLayout>
        <DashboardBoxLayout className='flex-1 flex flex-col gap-5'>
          <DashboardBoxTitle title='The comparison IS' className=' !text-[--theme]' />
          <div className='p-4 border border-[#F2F4F7] rounded-[20px]'>
            <div className='flex items-center gap-3 mb-5'>

              <div className="flex-1">
                <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                  {t('Costs')}
                </span>
              </div>
              <ConditionalRender condition={hasPermissionToCreateComparison}>
              <div className="flex-none">
                <div
                  className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                  onClick={() => { setIsAddCostModalOpen('Ist') }}
                >
                  <Icon iconType={IconType.PLUS_ICON} color='#fff' />
                </div>
              </div>
              </ConditionalRender>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Direct Costs')}</span>
                <span className='!font-inter-semibold'>{ISTCosts?.cost_1 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Indirect Costs')}</span>
                <span className='!font-inter-semibold'>{ISTCosts?.cost_2 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Employee cosmissions')}</span>
                <span className='!font-inter-semibold'>{ISTCosts?.cost_3 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span className='!font-inter-bold'>
                  {ISTCosts?.total || 0} CHF
                </span>
              </div>
            </div>
          </div>
          <div className='p-4 border border-[#F2F4F7] rounded-[20px]'>
            <div className='flex items-center gap-3 mb-5'>

              <div className="flex-1">
                <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                  {t('Actual Turnover (IST)')}
                </span>
              </div>
              <ConditionalRender condition={hasPermissionToCreateComparison}>
              <div className="flex-none">
                <div
                  className="w-[36px] h-[36px] bg-[--theme] grid place-items-center cursor-pointer rounded-[8px]"
                  onClick={() => { setIsAddTurnoverModalOpen('Ist') }}
                >
                  <Icon iconType={IconType.PLUS_ICON} color='#fff' />
                </div>
              </div>
              </ConditionalRender>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Health insurance revenue')}</span>
                <span className='!font-inter-semibold'>{ISTTurnOver?.cost_1 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Life insurance revenue')}</span>
                <span className='!font-inter-semibold'>{ISTTurnOver?.cost_2 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Overheard revenue')}</span>
                <span className='!font-inter-semibold'>{ISTTurnOver?.cost_3 || 0} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span className='!font-inter-bold'>
                  {ISTTurnOver?.total || 0} CHF
                </span>
              </div>
            </div>
          </div>
          <div className={`p-4 border border-[#F2F4F7] rounded-[20px]
           ${ISTTurnOver?.total - ISTCosts?.total > 0 ? '!border-[#2CC95B]' : '!border-[#F45B69]'}
          `}>
            <div className='flex items-center gap-3 mb-5'>
              <span className='text-[20px] text-primaryTextColor font-inter-bold'>
                {t("Net Profit")}
              </span>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Total costs')}</span>
                <span className='!font-inter-semibold'>{ISTCosts?.total} CHF</span>
              </div>
              <div className='flex justify-between gap-3 items-center text-primaryTextColor font-inter-regular'>
                <span className=''>{t('Total revenue')}</span>
                <span className='!font-inter-semibold'>{ISTTurnOver?.total} CHF</span>
              </div>

              <div className='flex justify-between gap-3 items-center text-[--theme] font-inter-regular'>
                <span className='!font-inter-bold'>Total + / -</span>
                <span
                  className={`!font-inter-semibold ${ISTTurnOver?.total - ISTCosts?.total > 0 ? 'text-[#2CC95B]' : 'text-[#F45B69]'}`}
                >
                  {(ISTTurnOver?.total - ISTCosts?.total) || 0} CHF
                </span>
              </div>
            </div>
          </div>



        </DashboardBoxLayout>

      </div>
      <AddNewCost
        hocCurrentStep={1}
        title={IsAddCostModalOpen}
        onConfirm={() => { setIsAddCostModalOpen(undefined) }}
        hocisOpen={IsAddCostModalOpen !== undefined}
        hocToggleModal={() => setIsAddCostModalOpen(undefined)}
        hocTitle={'Add Cost'}
        hocClasses={{
          modalClassName: "w-full md:max-w-[500px]",
          titleClassName: ""
        }}
      />
      <AddNewTurnover
        hocCurrentStep={1}
        title={IsAddTurnoverModalOpen}
        onConfirm={() => { setIsAddTurnoverModalOpen(undefined) }}
        hocisOpen={IsAddTurnoverModalOpen !== undefined}
        hocToggleModal={() => setIsAddTurnoverModalOpen(undefined)}
        hocTitle={IsAddTurnoverModalOpen === 'Soll' ? 'Add Tunover (SOLL)' : 'Actual Turnover (IST)'}
        hocClasses={{
          modalClassName: "w-full md:max-w-[500px]",
          titleClassName: ""
        }}
      />

    </div>
  )
}

export default FinanceComparisonView