import { deleteInsuranceOverview } from "api/Documents";
import { queryClient } from "lib/helpers/configs/queryClient";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useDeleteOverview = () => {

    const { t } = useTranslation()

    return useMutation(deleteInsuranceOverview, {
        onSuccess: () => {
            queryClient.invalidateQueries("resources");

            toast.success(t("Deleted successfully"));
        },
        onError: () => {
            toast.error(t("Failed to delete"));
        },
    });
};
