import { FC, useEffect, useState } from "react";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import WonAppointment from "../status-appointments/won-appointment/WonAppointment";
import FollowUpAppointment from "../status-appointments/follow-up-appointment/FollowUpAppointment";
import PendingAppointment from "../status-appointments/pending-appointment/PendingAppointment";
import LostAppointment from "../status-appointments/lost-appointment/LostAppointment";
import SendEmailAppointment from "./update-appointment-step/SendEmailAppointment";
import CallAppointment from "./update-appointment-step/CallAppointment";
import withModal from "lib/helpers/hoc/withModal";
import Icon from "components/atoms/icon/Icon";
import { Link } from "react-router-dom";
import { useWonFeedback } from "lib/hooks/mutations/Appointments/useWonFeedback";
import IconButton from "components/atoms/button/IconButton";
import { useForm } from "react-hook-form";
import Form from "components/atoms/form/Form";
import { useSwitchAppointment } from "lib/hooks/mutations/Appointments/useSwitchAppointment";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import CounterControl from "components/molecules/counter-control-append/CounterControlAppend";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { useAppointmentForm } from "lib/hooks/forms/useAppointmentForm";
import { Controller } from "react-hook-form";
import Button from "components/atoms/button/Button";
import { useUpdateAppointment } from "lib/hooks/mutations/Appointments/useUpdateAppointment";
import { useAppointmentActivityLogs } from "lib/hooks/queries/Appointment/useAppointmentActivityLogs";
import ActivityLogItem from "components/molecules/activity-log-item/ActivityLogItem";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { useLocation } from "react-router-dom";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { getStatusColor } from "lib/hooks/shared/getStatusColorAppointment";
import { IconType } from "lib/helpers/constants/iconTypes";
import LocationIcon from "components/atoms/icon/icon-type/LocationIcon";
import EmailIcon from "components/atoms/icon/icon-type/EmailIcon";
import PhoneIcon from "components/atoms/icon/icon-type/PhoneIcon";
import UploadAppointmentFile from "./upload-appointment-file/UploadAppointmentFile";
import { useAppointmentDocument } from "lib/hooks/queries/Appointment/useAppointmentDocument";
import AddAppointmentAddress from "../Add-new-appointment-address/AddAppointmentAddress";
import { languageData } from "lib/helpers/constants/languageData";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import { Worker } from "@react-pdf-viewer/core";
import Comments from "components/molecules/comments/Comments";
import { useAppointmentNote } from "lib/hooks/mutations/Appointments/useAppointmentNote";
import { useAppointmentNotes } from "lib/hooks/queries/Appointment/useAppointmentNotes";
import { useTranslation } from "react-i18next";
import { useAppointmentSource } from "lib/hooks/queries/Appointment/useAppointmentSource";

interface ActivityLogData<T> {
  data: T;
}

export interface IUpdateAppointmentProcessProps<T> {
  onConfirm: () => void;
  data: T;
  showStatusButton: boolean;
  showDigitalButton: boolean;
  switchTabs?: any;
  onCancel: () => void;
  showReturnToAppointmentListButton?: boolean;
  setCurrentFormStep: (step: number) => void;
  currentStep: number;
  setIsUpdateModalOpen: (value: boolean) => void;
  themeColors: {
    primary: string;
  };
}

const UpdateAppointmentProcess: FC<IUpdateAppointmentProcessProps<any>> = ({
  onConfirm,
  data,
  showStatusButton,
  showDigitalButton,
  switchTabs,
  showReturnToAppointmentListButton,
  onCancel,
  setCurrentFormStep,
  currentStep,
  setIsUpdateModalOpen,
  themeColors,
}) => {
  const {
    formState: { errors: wonErrors },
    handleSubmit: wonHandleSubmit,
    register: wonRegister,
    control: wonControl,
    reset: resetWon,
    trigger: triggerWon,
    getValues: getValuesWon,
    clearErrors: clearErrosWon,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState();
  const [activeTab, setActiveTab] = useState<number>(1);
  const { mutate: updateAppointment, isLoading: isLoadingUpdate } =
    useUpdateAppointment({
      onSettled: (data, error) => {
        if (error) {
          setErrorMessage(error.response.data.message);
          setCurrentFormStep(11);
        } else {
          onConfirm();
        }
      },
    });
  const {
    data: appointmentDocumentData,
    refetch: refetchDocuments,
    isLoading: isLoadingGettingDocuments,
  } = useAppointmentDocument(
    {
      appointment_id: data.id,
    },
    {
      enabled: activeTab === 2 ? true : false,
    }
  );

  const {
    data: activityLogData,
    refetch: refetchLogs,
    isLoading: isLoadingLogs,
  } = useAppointmentActivityLogs(
    {
      appointment_id: data.id,
    },
    {
      enabled: activeTab === 3 ? true : false,
    }
  );

  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const location = useLocation();
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,

  } = useAppointmentForm({
    defaultValues: {
      ...data,
      ...data?.info,
    },
  });

  const necessaryFields = [
    "first_name",
    "last_name",
    "nationality",
    "birthdate",
    "current_health_insurance",
    "persons",
    "insurance_type",
    "source",
    "language",
    "phone_number",
    "email",
    "date",
    "time",
    "address",
    "nr",
    "postal_code",
    "city",
    "note",
    "assigned_to",
  ];

  const onSubmit = (formData: any) => {

    const updateFilter = Object.keys(formData).reduce(
      (acc: { [key: string]: any }, key) => {
        if (necessaryFields.includes(key)) {
          acc[key] = formData[key];
        }
        return acc;
      },
      {}
    );
    updateAppointment({
      id: data.id,
      data: updateFilter,
    });
  };

  const [MapAddress, setMapAddress] = useState({
    postal_code: "",
    address: "",
    nr: "",
    city: "",
  });

  useEffect(() => {
    getUsersByPermission({
      permissions: ['apointment_view_view'],
    })
      .then((res) => {
        setFetchedUsers(res.users);
      })
      .catch(() => { });
    // eslint-disable-next-line
  }, []);

  const [storeData, setStoreData] = useState<any>();
  const { mutate: chooseWonStatus } = useWonFeedback();
  const { mutate: switchAppointment } = useSwitchAppointment();

  const [numberOfPersonsWithData, setNumberOfPersonsWithData] =
    useState<number>(0);

  const onConfirmationSubmit = () => {
    const filteredStoreData = {
      ...storeData,
      persons: storeData?.persons?.filter(
        (person: any) => person.products.length > 0
      ),
    };

    chooseWonStatus({
      appointment_id: data?.id,
      ...filteredStoreData,
    });

    setCurrentFormStep(4);
  };

  const [addresses, setAddresses] = useState<any>([]);

  const handleAddressAdded = (newAddress: any) => {
    setAddresses((prevAddresses: any) => [...prevAddresses, newAddress]);
  };
  const combinedAddresses = [...(data?.addresses || []), ...(addresses || [])];

  const [comment, setComment] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<any>();
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);


  const {
    refetch: refetchNotes,
    isLoading: isLoadingNotes,
  } = useAppointmentNotes(
    {
      appointment_id: data.id,
    }
  );

  const { mutateAsync: sendNote, isLoading: sendNoteLoading } = useAppointmentNote();

  const [AppointmentNotes, setAppointmentNotes] = useState(data.notes)

  const handlesendNote = () => {

    const noteData = {
      appointment_id: data?.id,
      note: comment,
    }
    if (imageBlob) {
      const reader = new FileReader();


      reader.readAsDataURL(imageBlob);

      reader.onload = () => {


        const dataWithImage = { ...noteData, image: reader.result as string };

        sendNote(dataWithImage).then(() => {


          refetchNotes().then((res) => {

            setComment("");
            setImagePreview(null);
            setImageBlob(null);
            setAppointmentNotes(res?.data?.notes);
          })
        })

      };

    } else {
      sendNote(noteData).then(() => {

        refetchNotes().then((res) => {

          setComment("");
          setImagePreview(null);
          setImageBlob(null);
          setAppointmentNotes(res?.data?.notes);
        })
      })

    }
  };

  const { t } = useTranslation();

  const hasEditPermission = usePermissions(['apointment_view_edit']);

  const { data: appointmentSourceData, isLoading: isLoadingAppSource } = useAppointmentSource({ appointment_id: data.id });


  return (
    <div className="mb-0 flex flex-col gap-7">
      <ConditionalRender condition={currentStep === 1}>
        <div className="absolute top-[26px] items-center sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]">
          {data?.info?.first_name ?? ""} {data?.info?.last_name ?? ""}
          <span
            className={`font-inter-regular text-[14px] p-1 w-[80px] text-center rounded-[8px] ${getStatusColor(
              data?.feedback_status
            )}`}
          >
            {t(data?.feedback_status) ?? ""}
          </span>
          <ConditionalRender condition={!isLoadingAppSource}>
            <>
              <ConditionalRender condition={appointmentSourceData?.source}>
                <div
                  className={`font-inter-regular text-[14px] py-1 px-2  text-center rounded-[8px] bg-[#7D85921A] text-[#7D8592]`}
                >
                  {t('Source')} : {appointmentSourceData?.source}, {appointmentSourceData?.sourceName}
                </div>
              </ConditionalRender>
              <div
                className={`font-inter-regular text-[14px] py-1 px-2  text-center rounded-[8px] bg-[#7D85921A] text-[#7D8592]`}
              >
                {t('From')} : {appointmentSourceData?.user}
              </div>
            </>
          </ConditionalRender>

        </div>
        <div className={`flex flex-col lg:flex-row relative`}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="mb-0 flex flex-col"
          >
            <div className="flex flex-col lg:flex-row  gap-[55px] ">
              <div>
                <div className="flex flex-col sticky top-0">
                  <div className="flex-none">
                    <div className="w-full lg:w-[300px] rounded-[10px] border border-[#A2A1A833] white-space-nowrap ">
                      <div
                        onClick={() => {
                          setActiveTab(1);
                        }}
                        className={`px-6 py-4 rounded-t-[10px] flex gap-3 items-center ${activeTab === 1
                          ? `text-[#fff] bg-[--theme] font-inter-semibold`
                          : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                          }`}
                      >
                        <Icon
                          iconType={IconType.APPOINTMENT_CALENDAR_ICON}
                          color={activeTab === 1 ? "#fff" : "#282D46"}
                        />
                        <span>{t('Appointment Information')}</span>
                      </div>
                      <ConditionalRender condition={usePermissions(['apointment_documents_view'])}>
                        <div
                          onClick={() => {
                            setActiveTab(2);
                            refetchDocuments();
                          }}
                          className={`px-6 py-4 flex gap-3 items-center ${activeTab === 2
                            ? `text-[#fff] bg-[--theme] font-inter-semibold`
                            : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                            }`}
                        >
                          <Icon
                            iconType={IconType.DOCUMENT_ICON}
                            color={activeTab === 2 ? "#fff" : undefined}
                          />

                          <span>{t('Documents')}</span>
                        </div>

                      </ConditionalRender>
                      <ConditionalRender condition={usePermissions(['apointment_activitylog_view'])}>
                        <div
                          onClick={() => {
                            setActiveTab(3);
                            refetchLogs();
                          }}
                          className={`px-6 py-4 rounded-b-[10px] flex gap-3 items-center ${activeTab === 3
                            ? `text-[#fff] bg-[--theme] font-inter-semibold`
                            : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                            }`}
                        >
                          <Icon
                            iconType={IconType.ACTIVITY_LOG_ICON}
                            color={activeTab === 3 ? "#fff" : undefined}
                          />

                          <span>{t('Activity Log')}</span>
                        </div>
                      </ConditionalRender>
                    </div>
                  </div>
                  <ConditionalRender
                    condition={
                      activeTab === 1 &&
                      data?.feedback_status !== "Lost" &&
                      data?.feedback_status !== "Won"
                    }
                  >
                    <ConditionalRender condition={showDigitalButton && hasEditPermission}>
                      <div className="flex flex-col gap-4 pt-[30px]">
                        <h1 className="text-[#282D46] text-[16px] font-inter-medium leading-[24px]">
                          {t('Convert Appointment To')}{" "}
                          {switchTabs ? "Digital" : "Physical"}
                        </h1>
                        <Button
                          type="button"
                          onClick={() => setCurrentFormStep(10)}
                          className={`flex justify-center py-3 bg-[--theme]  items-center gap-3 rounded-[8px] text-[#fff] text-[16px] font-inter-bold leading-[19.36px] cursor-pointer`}
                        >
                          {t('Convert To')} {switchTabs ? t("Digital") : t("Physical")}
                          <Icon iconType={IconType.CONVERT_ICON} />
                        </Button>
                      </div>
                    </ConditionalRender>
                    <ConditionalRender condition={showStatusButton}>
                      <div className="flex flex-col gap-4 pt-[30px]">
                        <h1 className="text-[#282D46] text-[16px] font-inter-medium leading-[24px]">
                          {t('Appointment Status')}
                        </h1>
                        <Button
                          type="button"
                          onClick={() => {
                            setCurrentFormStep(2);
                          }}
                          className="flex justify-center py-3 bg-[#2DE28B] rounded-[8px] text-[#fff] text-[16px] font-inter-bold leading-[19.36px] cursor-pointer"
                        >
                          {t('Won')}
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentFormStep(5);
                          }}
                          className="flex justify-center py-3 bg-[#CC7272] rounded-[8px] text-[#fff] text-[16px] font-inter-bold leading-[19.36px] cursor-pointer"
                        >
                          {t('Lost')}
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentFormStep(6);
                          }}
                          className="flex justify-center py-3 bg-[#70DDE4] rounded-[8px] text-[#fff] text-[16px] font-inter-bold leading-[19.36px] cursor-pointer"
                        >
                          {t('Follow Up')}
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentFormStep(7);
                          }}
                          className="flex justify-center py-3 bg-[#EFBE12] rounded-[8px] text-[#fff] text-[16px] font-inter-bold leading-[19.36px] cursor-pointer"
                        >
                          {t('Pending')}
                        </Button>
                      </div>
                    </ConditionalRender>
                  </ConditionalRender>
                </div>
              </div>

              <div>
                <ConditionalRender condition={activeTab === 1}>
                  <div className="flex flex-row gap-4 absolute right-0">
                    <div
                      className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min`}
                    >
                      <LocationIcon color="#fff" />
                    </div>
                    <div
                      className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min cursor-pointer`}
                      onClick={() => setCurrentFormStep(8)}
                    >
                      <EmailIcon color="#fff" />
                    </div>
                    <div
                      className={`bg-[--theme] py-[6px] px-[10px] rounded-[5px] w-min cursor-pointer`}
                      onClick={() => setCurrentFormStep(9)}
                    >
                      <PhoneIcon color="#fff" />
                    </div>
                  </div>
                </ConditionalRender>
                <ConditionalRender condition={activeTab === 1}>
                  <div className="flex flex-col gap-5">
                    <InputsFolder title={t('Personal information')}>
                      <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                          <Input
                            label={t('First Name')}
                            placeholder={t('First Name')}
                            {...register("first_name")}
                            error={errors?.first_name}
                            readOnly={
                              data?.feedback_status === "Won" ||
                              data?.feedback_status === "Lost"
                            }
                          />
                          <Input
                            label={t('Last Name')}
                            placeholder={t('Last Name')}
                            {...register("last_name")}
                            error={errors?.last_name}
                            readOnly={
                              data?.feedback_status === "Won" ||
                              data?.feedback_status === "Lost"
                            }
                          />
                          <Input
                            label={t('Nationality')}
                            placeholder={t('Nationality')}
                            {...register("nationality")}
                            readOnly={
                              data?.feedback_status === "Won" ||
                              data?.feedback_status === "Lost"
                            }
                          />
                          <Controller
                            control={control}
                            name="birthdate"
                            rules={{ required: t("Date of birth is required") }}
                            render={({ field: { onChange, value } }) => (
                              <PrimaryDatePicker
                                label={t('Date Of Birth')}
                                startDate={value}
                                error={errors?.birthdate}
                                isDisabled={
                                  data?.feedback_status === "Won" ||
                                  (data?.feedback_status === "Lost" && true)
                                }
                                {...register("birthdate")}
                                setStartDate={(date: string) => onChange(date)}
                              />
                            )}
                          />
                          <Input
                            label={t('Current Health Insurance')}
                            placeholder={t('Current Health Insurance')}
                            {...register("current_health_insurance")}
                            error={errors?.current_health_insurance}
                            readOnly={
                              data?.feedback_status === "Won" ||
                              data?.feedback_status === "Lost"
                            }
                          />
                          <div>
                            <Label>{t('Persons in Household')}</Label>
                            <div className="w-full">
                              <Controller
                                control={control}
                                name="persons"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <CounterControl
                                    isDisabled={
                                      data?.feedback_status === "Won" ||
                                      data?.feedback_status === "Lost"
                                    }
                                    count={value}
                                    className="!mt-0"
                                    classes={{
                                      container:
                                        "w-full bg-white !border-inputborder",
                                    }}
                                    onIncrement={() => onChange(value + 1)}
                                    onDecrement={() =>
                                      onChange(value >= 1 ? value - 1 : value)
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="relative">
                            <Label text={t('Insurance Type')} />
                            <Controller
                              name="insurance_type"
                              control={control}
                              render={({ field }) => (
                                <Creatable<any, false>
                                  {...field}
                                  placeholder={t('Choose Insurance')}
                                  options={[
                                    {
                                      value: "Health Insurance",
                                      label: t('Health Insurance'),
                                    },
                                    {
                                      value: "Physical",
                                      label: t('Physical'),
                                    },
                                  ]}
                                  value={
                                    field.value
                                      ? {
                                        value: field.value,
                                        label: field.value,
                                      }
                                      : null
                                  }
                                  defaultValue={
                                    data?.info?.insurance_type
                                      ? {
                                        value: data.info.insurance_type,
                                        label: data.info.insurance_type,
                                      }
                                      : null
                                  }
                                  defaultInputValue={
                                    data?.info?.insurance_type || ""
                                  }
                                  className="cursor-pointer font-inter-regular react-select-container"
                                  classNamePrefix="react-select"
                                  onChange={(
                                    newValue: any,
                                    actionMeta: any
                                  ) => {
                                    if (
                                      actionMeta.action === "select-option" &&
                                      newValue?.value
                                    ) {
                                      field.onChange(newValue.value);
                                    }
                                  }}
                                  styles={customStyles}
                                  isValidNewOption={() => false}
                                  menuPosition={"fixed"}
                                  menuPortalTarget={document.body}
                                  isDisabled={
                                    data?.feedback_status === "Won" ||
                                    data?.feedback_status === "Lost"
                                  }
                                />
                              )}
                            />
                          </div>
                          <Input
                            label={t('Source')}
                            placeholder={t('Source')}
                            {...register("source")}
                            error={errors?.source}
                            disabled={true}
                          />
                        </div>
                        <ConditionalRender
                          condition={combinedAddresses?.length >= 1}
                        >
                          <div className="mt-[20px]">
                            {combinedAddresses?.map?.(
                              (
                                item: {
                                  address: string;
                                  city: string;
                                  nr: string;
                                  type: string;
                                  zip_code: string;
                                },
                                index: number
                              ) => {
                                return (
                                  <div
                                    key={index}
                                    className="grid grid-cols-4 gap-5"
                                  >
                                    <div className="col-span-1">
                                      <Input
                                        label={t('Street')}
                                        defaultValue={item.address}
                                        className="h-[44px] mb-5"
                                        readOnly
                                        icon={IconType.LOCATION_ICON}
                                        classes={{
                                          icon: "absolute top-[calc(57%-2px)] transform -translate-y-1/2 right-2 bg-white",
                                        }}
                                        {...register(
                                          `addresses.${index}.address`,
                                          {
                                            required: t("Address field is required"),
                                          }
                                        )}
                                      />
                                    </div>
                                    <div className="col-span-1">
                                      <Input
                                        readOnly
                                        label={t('No.')}
                                        defaultValue={item.type}
                                        className="h-[44px] mb-5"
                                        {...register(
                                          `addresses.${index}.type`,
                                          {
                                            required: t("Type field is required"),
                                          }
                                        )}
                                      />
                                    </div>
                                    <div className="col-span-1">
                                      <Input
                                        readOnly
                                        label={t('Zip')}
                                        defaultValue={item.zip_code}
                                        className="h-[44px] mb-5"
                                        {...register(
                                          `addresses.${index}.zip_code`,
                                          {
                                            required: t("Zip Code field is required"),
                                          }
                                        )}
                                      />
                                    </div>
                                    <div className="col-span-1">
                                      <Input
                                        readOnly
                                        label={t('City')}
                                        defaultValue={item.city}
                                        className="h-[44px] mb-5"
                                        {...register(
                                          `addresses.${index}.city`,
                                          {
                                            required: t("City field is required"),
                                          }
                                        )}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </ConditionalRender>
                      </>
                    </InputsFolder>
                    <InputsFolder title={t('Contact information')}>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                        <div className="relative">
                          <Label text={t('Language')} />
                          <Controller
                            name="language"
                            control={control}
                            rules={{ required: t("Language is required") }}
                            render={({ field }) => (
                              <Creatable<any, false>
                                {...field}
                                options={
                                  languageData?.map?.((language) => ({
                                    value: language.value,
                                    label: language.label,
                                  })) || []
                                }
                                defaultValue={
                                  data?.info?.language
                                    ? {
                                      value: data?.info?.language,
                                      label: data?.info?.language,
                                    }
                                    : null
                                }
                                defaultInputValue={data?.info?.language || ""}
                                value={
                                  field.value
                                    ? { value: field.value, label: field.value }
                                    : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(newValue: any, actionMeta: any) => {
                                  if (
                                    actionMeta.action === "select-option" &&
                                    newValue?.value
                                  ) {
                                    field.onChange(newValue.value);
                                  } else if (actionMeta.action === "clear") {
                                    field.onChange("");
                                  }
                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                                isDisabled={
                                  data?.feedback_status === "Won" ||
                                  data?.feedback_status === "Lost"
                                }
                              />
                            )}
                          />
                          {errors.language?.message && (
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                              <>{errors.language?.message as string}</>
                            </div>
                          )}
                        </div>
                        <div className="flex gap-3">
                          <div className="flex-1">
                            <Input
                              placeholder={t('Enter phone number')}
                              label={t('Phone')}
                              {...register("phone_number")}
                              error={errors.phone_number}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                          </div>
                          <div className="flex-none pt-[35px]">
                            <PhoneIcon />
                          </div>
                        </div>

                        <div className="flex gap-3">
                          <div className="flex-1">
                            <Input
                              placeholder={t('Enter email')}
                              label={t('Email')}
                              {...register("email")}
                              error={errors?.email}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                          </div>
                          <div className="flex-none pt-[40px]">
                            <EmailIcon />
                          </div>
                        </div>
                      </div>
                    </InputsFolder>
                    <div>
                      <InputsFolder title={t('Appointment details')}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pb-2">
                          <Controller
                            control={control}
                            name="date"
                            rules={{
                              required: t("Date of appointment is required"),
                            }}
                            render={({ field: { onChange, value } }) => (
                              <PrimaryDatePicker
                                label={t('Appointment Date')}
                                startDate={value}
                                isDisabled={true}
                                {...register("date")}
                                setStartDate={(date: string) => onChange(date)}
                              />
                            )}
                          />
                          {/* <CustomSelect
                          placeholder=''
                          disabled={true}
                          label="Appointment Time"
                          error={errors?.time}
                          {...register("time")}
                          customOptions={timeOptions}
                        /> */}
                          <Input
                            disabled={true}
                            label={t('Appointment Time')}
                            error={errors?.time}
                            {...register("time")}
                            readOnly={
                              data?.feedback_status === "Won" ||
                              data?.feedback_status === "Lost"
                            }
                          />
                          <div className="grid grid-cols-2 gap-3">
                            <Input
                              placeholder={t('Enter street')}
                              label={t('Street')}
                              icon={IconType.LOCATION_ICON}
                              {...register("address")}
                              classes={{ icon: "!bg-white right-[8px]" }}
                              error={errors?.address}
                              onChange={(e) => {
                                setMapAddress({
                                  ...MapAddress,
                                  address: e.target.value,
                                });
                              }}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                            <Input
                              placeholder={t('Enter Nr')}
                              label={t('No')}
                              {...register("nr")}
                              error={errors?.nr}
                              onChange={(e) => {
                                setMapAddress({
                                  ...MapAddress,
                                  nr: e.target.value,
                                });
                              }}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                            <Input
                              placeholder={t('ZIP')}
                              label={t('PLZ')}
                              {...register("postal_code")}
                              error={errors?.postal_code}
                              onChange={(e) => {
                                setMapAddress({
                                  ...MapAddress,
                                  postal_code: e.target.value,
                                });
                              }}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                            <Input
                              placeholder={t('City')}
                              label={t('City')}
                              {...register("city")}
                              error={errors?.city}
                              onChange={(e) => {
                                setMapAddress({
                                  ...MapAddress,
                                  city: e.target.value,
                                });
                              }}
                              readOnly={
                                data?.feedback_status === "Won" ||
                                data?.feedback_status === "Lost"
                              }
                            />
                          </div>
                          <div>
                            <iframe
                              className="border-inputborder border rounded-[8px]"
                              title="map"
                              src={`https://maps.google.com/maps?q='+${MapAddress.postal_code} ${MapAddress.address} ${MapAddress.city}  ${MapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                              style={{ width: "100%", height: "100%" }}
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </div>
                      </InputsFolder>
                      <IconButton
                        type="button"
                        secondary={true}
                        onClick={() => setCurrentFormStep(12)}
                        className="border-none flex justify-end ml-auto  w-[220px]"
                        icon={<Icon iconType={IconType.INCRASE_ICON} />}
                      >
                        {t('Add home address')}
                      </IconButton>
                    </div>
                    <InputsFolder title={t('Related details')}>
                      <>

                        <div
                          className={`grid  gap-5 ${location.pathname === "/appointments"
                            ? "grid-cols-2"
                            : "grid-cols-1"
                            }`}
                        >
                          <ConditionalRender
                            condition={
                              usePermissions(['assign_apointments_assign']) &&
                              data?.assigned_to == null
                            }
                          >
                            <div>
                              <div className="relative">
                                <Label text={t('Assigned to')} />
                                <Controller
                                  name="assigned_to"
                                  control={control}
                                  rules={{ required: t("Assigned to is required") }}
                                  render={({ field }) => (
                                    <Creatable<any, false>
                                      {...field}
                                      placeholder={t('Assign to')}
                                      options={
                                        (
                                          fetchedUsers as unknown as Array<{
                                            name: string;
                                            id: string;
                                          }>
                                        )?.map?.((user) => ({
                                          label: user?.name,
                                          value: user?.id,
                                        })) ?? []
                                      }
                                      value={
                                        field?.value
                                          ? {
                                            value: field?.value,
                                            label:
                                              (
                                                fetchedUsers?.find?.(
                                                  (user: any) =>
                                                    user.id === field.value
                                                ) as
                                                | { name: string }
                                                | undefined
                                              )?.name || field.value,
                                          }
                                          : null
                                      }
                                      className="cursor-pointer font-inter-regular react-select-container"
                                      classNamePrefix="react-select"
                                      onChange={(
                                        newValue: any,
                                        actionMeta: any
                                      ) => {
                                        if (
                                          actionMeta.action === "select-option" &&
                                          newValue?.value
                                        ) {
                                          field.onChange(newValue.value);
                                        }
                                      }}
                                      styles={customStyles}
                                      isValidNewOption={() => false}
                                      menuPosition={"fixed"}
                                      menuPortalTarget={document.body}
                                      isDisabled={
                                        data?.feedback_status === "Won" ||
                                        data?.feedback_status === "Lost"
                                      }
                                    />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
                            </div>
                          </ConditionalRender>

                        </div>
                        <div className="pt-5">
                          <Comments
                            handleSend={handlesendNote}
                            data={AppointmentNotes}
                            setImageBlob={setImageBlob}
                            comment={comment}
                            setComment={setComment}
                            imagePreview={imagePreview}
                            setImagePreview={setImagePreview}
                            isLoading={sendNoteLoading || isLoadingNotes}
                          />
                        </div>
                      </>
                    </InputsFolder>
                    <ConditionalRender condition={usePermissions(['apointment_view_edit'])}>
                      <div className="flex justify-end gap-[26px]">
                        {data?.feedback_status === "Won" ||
                          data?.feedback_status === "Lost" ? null : (
                          <IconButton
                            type="submit"
                            disabled={isLoadingUpdate ? true : false}
                          >
                            {t('Update')}
                          </IconButton>
                        )}
                      </div>
                    </ConditionalRender>
                  </div>
                </ConditionalRender>
              </div>
            </div>
          </Form>
          <ConditionalRender condition={activeTab === 2}>
            <UploadAppointmentFile
              onConfirm={onConfirm}
              appointmentData={appointmentDocumentData}
              isLoadingDocument={isLoadingGettingDocuments}
              data={data ?? ""}
              themeColors={themeColors}
            />
          </ConditionalRender>
          <ConditionalRender condition={activeTab === 3}>
            <ActivityLogItem
              data={
                (activityLogData as { data?: any })
                  ?.data as ActivityLogData<any>
              }
              isLoading={isLoadingLogs}
              showReview={true}
              href="appointment"
            />
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <WonAppointment
          themeColors={themeColors}
          onConfirm={onConfirm}
          data={data}
          setCurrentStep={setCurrentFormStep}
          currentStep={currentStep}
          setNumberOfPersonsWithData={setNumberOfPersonsWithData}
          setStoreData={setStoreData}
          formState={wonErrors}
          handleSubmit={wonHandleSubmit}
          register={wonRegister}
          control={wonControl}
          reset={resetWon}
          trigger={triggerWon}
          getValues={getValuesWon}
          clearErrors={clearErrosWon}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <Form
          onSubmit={wonHandleSubmit(onConfirmationSubmit)}
          className="mb-0 flex flex-col gap-7 !max-w-[572px]"
        >
          <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[17px] text-[#282D46] font-bold text-[22px]">
            {t('Information')}
          </h1>
          <p className="flex flex-col gap-8   font-inter-medium text-[#282D46]  text-[20px]">
            {t('In the appointment there are')} {data?.info?.persons ?? ""} {t("people in the household, and you've provided data for")} {" "}
            {numberOfPersonsWithData} {t('person')}
            {numberOfPersonsWithData !== 1 ? "s" : ""}. {t('Are you sure you want to proceed')}?
          </p>
          <div className="flex justify-end gap-4">
            <IconButton
              onClick={() => setCurrentFormStep(2)}
              secondary={true}
              type="button"
              className="w-full max-w-[150px]"
            >
              {t('Back')}
            </IconButton>
            <IconButton type="submit" className="w-full max-w-[150px]">
              {t('Confirm')}
            </IconButton>
          </div>
        </Form>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <div className="mb-0 flex flex-col justify-center items-center gap-7 pb-7">
          <div>
            <Icon
              iconType={IconType.SUCCESSFUL_LOGIN_ICON}
              color={themeColors.primary}
            />
          </div>
          <div className="text-[20px] font-inter-bold leading-[17px]">
            {t('Congratulations')}!
          </div>
          <div className="text-[17px] font-inter-medium leading-[17px]">
            {t('Your won persons have been converted to customers successfully')}.
          </div>
          <div className="flex w-full px-20">
            <Link
              onClick={() => {
                onConfirm();
                setCurrentFormStep(1);
              }}
              to={"/appointments"}
              className="bg-[--theme] block text-center text-white py-3  w-full rounded-[8px] text-[13px] font-inter-semibold leading-[22.75px]"
            >
              {t('Go to appointments')}
            </Link>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 5}>
        <LostAppointment
          onConfirm={onConfirm}
          themeColors={themeColors}
          data={data}
          setCurrentStep={setCurrentFormStep}
          setPreviewURL={setPreviewURL}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 6}>
        <FollowUpAppointment
          onConfirm={onConfirm}
          data={data}
          themeColors={themeColors}
          setCurrentStep={setCurrentFormStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 7}>
        <PendingAppointment
          onConfirm={onConfirm}
          data={data}
          themeColors={themeColors}
          setCurrentStep={setCurrentFormStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 8}>
        <SendEmailAppointment
          onConfirm={onConfirm}
          onCancel={onCancel}
          data={data}
          setCurrentFormStep={setCurrentFormStep}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 9}>
        <CallAppointment
          onConfirm={onConfirm}
          onCancel={onCancel}
          data={data}
          setCurrentFormStep={setCurrentFormStep}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 10}>
        <div className="mb-0 flex flex-col gap-7">
          <div className="mb-0 flex flex-col gap-[37px] w-full">
            <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[17px] text-[#282D46] font-inter-semibold text-[22px]">
              {t('Are you sure?')}
            </h1>
            <p className="flex flex-col   font-inter-medium text-[#282D46] mt-[10px] text-[20px]">
              {t('Are you sure you want to convert this appointment from')}
              {switchTabs ? " Physical " : " Digital "} to
              {switchTabs ? " Digital " : " Physical "} ?
            </p>
            <div className="flex justify-end gap-[19px]">
              <IconButton
                onClick={() => setCurrentFormStep(1)}
                secondary={true}
                type="button"
                className="w-full max-w-[150px]"
              >
                {t('Back')}
              </IconButton>
              <IconButton
                onClick={() => {
                  switchAppointment({
                    appointment_id: data.id,
                    type: switchTabs ? "digital" : "physical",
                  });
                  setCurrentFormStep(1);
                  setIsUpdateModalOpen(false);
                }}
                type="submit"
                className="w-full max-w-[150px]"
              >
                {t('Confirm')}
              </IconButton>
            </div>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 11}>
        <div className="mb-0 flex flex-col gap-[37px]">
          <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[17px] text-[#282D46] font-inter-semibold text-[22px]">
            {t('Sorry! Assignment not possible')}
          </h1>
          <p className="flex flex-col   font-inter-medium text-[#282D46] mt-[10px] text-[20px]">
            {errorMessage}
          </p>
          <div className="flex justify-end">
            <IconButton
              onClick={() => setCurrentFormStep(1)}
              secondary={true}
              type="button"
              className="w-full max-w-[150px] bg-[--theme] !text-white"
            >
              {t('Back')}
            </IconButton>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 12}>
        <AddAppointmentAddress
          onConfirm={onConfirm}
          onCancel={onCancel}
          setCurrentFormStep={setCurrentFormStep}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
          data={data}
          onAddressAdded={handleAddressAdded}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 13}>
        <div>
          <Button
            type="button"
            onClick={() => {
              setCurrentFormStep(5)
            }}
            className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
          >
            <Icon
              iconType={IconType.BACK_BUTTON_MODAL}
              className="!w-[30px] !h-[30px]"
              color={themeColors.primary}
            />
            {t('Previous')}
          </Button>
          <div
            style={{ height: "100%" }}
          >
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
            >
              <img src={previewURL as any} alt="previewURL" className="w-full object-cover min-h-[500px] max-h-[800px]" />
            </Worker>
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default withModal(UpdateAppointmentProcess);
