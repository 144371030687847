import Step2CreateRole from 'components/organisms/employees-management/add-new-employee/add-new-employee-steps/Step2CreateRole'
import withModal from 'lib/helpers/hoc/withModal'
import React, { FC, useState } from 'react'

interface Props {
    onConfirm: () => void
}

const CreateRole: FC<Props> = ({ onConfirm }) => {

    const [Data, setData] = useState({
        permission: [],
    })
    return (
        <Step2CreateRole setAllData={setData} AllData={Data} withEmployee={false} onConfirm={onConfirm} />
    )
}

export default withModal(CreateRole)