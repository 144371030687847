import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Input from "components/atoms/input/Input";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import Label from "components/atoms/input/input-label/InputLabel";
import CounterControl from "components/molecules/counter-control-append/CounterControlAppend";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import { FC, Fragment } from "react";
import { format } from "date-fns";
import { useShowPassowrd } from "lib/hooks/shared/useShowPasswod";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { useHover } from "lib/hooks/shared/useHover";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Button from "components/atoms/button/Button";

interface IStep1EmployeeInfoProps {
  setCurrentStep: (step: number) => void;
  setAllData: (data: any) => void;
  AllData: any;
}

const Step1EmployeeInfo: FC<IStep1EmployeeInfoProps> = ({
  setCurrentStep,
  setAllData,
  AllData,
}) => {
  const { t } = useTranslation();
  const { showPassword, toggleShowPassword } = useShowPassowrd();
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      first_name: AllData?.first_name || "",
      last_name: AllData?.last_name || "",
      password: AllData?.password || "",
      email: AllData?.email || "",
      gender: AllData?.gender || null,
      position: AllData?.position || "",
      team: AllData?.team || "",
      department: AllData?.department || "",
      weekly_hours: AllData?.weekly_hours || 0,
      office_location: AllData?.office_location || "",
      supervisor: AllData?.supervisor || "",
      hire_date: AllData?.hire_date || format(new Date(), "yyyy-MM-dd"),
      length_of_probation: AllData?.length_of_probation || "",
      contract_ends: AllData?.contract_ends || null,
      vacation_days: AllData?.vacation_days || 0,
      on_boarding_template_id: AllData?.on_boarding_template_id || null,
    },
  });

  const onSubmit = (data: any) => {
    setCurrentStep(2);
    setAllData({ ...AllData, ...data });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-0 flex flex-col gap-5">
        <InputsFolder title={t("Public Profile")}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <Input
              label={t("First Name")}
              placeholder={t("Please enter first name")}
              {...register("first_name", {
                required: "This field is required",
              })}
              error={errors?.first_name}
            />
            <Input
              label={t("Last Name")}
              placeholder={t("Please enter last name")}
              {...register("last_name", { required: "This field is required" })}
              error={errors?.last_name}
            />
            <div className="relative">
              <Label text={t("Gender")} />
              <Controller
                name="gender"
                rules={{ required: "This field is required" }}
                control={control}
                render={({ field }) => (
                  <CreatableSelect<any, false>
                    {...field}
                    placeholder={t("Choose gender")}
                    options={[
                      { label: t("Male"), value: "Male" },
                      { label: t("Female"), value: "Female" },
                    ]}
                    value={
                      field?.value
                        ? {
                            value: field?.value,
                            label: t(field?.value),
                          }
                        : null
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              {errors.gender?.message && (
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  {errors.gender?.message as string}
                </div>
              )}
            </div>
            <Input
              label={t("Email")}
              placeholder={t("Enter email")}
              type="email"
              {...register("email", { required: "This field is required" })}
              error={errors?.email}
            />
            <Input
              label="Passwort"
              placeholder="••••••••"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: "This field is required" })}
              error={errors?.password}
              icon={
                showPassword
                  ? IconType.HIDDEN_PASSWORD_ICON
                  : IconType.SHOW_PASSWORD_ICON
              }
              toggleShowPassowrd={toggleShowPassword}
            />
          </div>
        </InputsFolder>
        <InputsFolder title={t("HR Information")}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <Input
              label={t("Position")}
              placeholder={t("Enter position")}
              {...register("position", { required: "This field is required" })}
              error={errors?.position}
            />
            <Input
              label={t("Team")}
              placeholder={t("Choose team")}
              type="text"
              {...register("team")}
              error={errors?.team}
            />
            <Input
              label={t("Department")}
              placeholder={t("Choose department")}
              type="text"
              {...register("department")}
              error={errors?.department}
            />

            <div>
              <Label>{t("Weekly Hours")}</Label>
              <div className="w-full">
                <Controller
                  control={control}
                  name="weekly_hours"
                  rules={{ required: "This field is required" }}
                  render={({ field: { onChange, value } }) => (
                    <CounterControl
                      count={value}
                      className="!mt-0"
                      classes={{
                        container: "w-full bg-white !border-inputborder",
                      }}
                      isInput
                      onChange={(e: any) => {
                        onChange(parseFloat(e.target.value));
                      }}
                      onIncrement={() => onChange(value + 1)}
                      onDecrement={() =>
                        onChange(value >= 1 ? value - 1 : value)
                      }
                    />
                  )}
                />
              </div>
            </div>
            <Input
              label={t("Office")}
              placeholder={t("Choose office")}
              type="text"
              {...register("office_location", {
                required: "This field is required",
              })}
              error={errors?.office_location}
            />
            <Input
              label={t("Supervisor")}
              placeholder={t("Choose supervisor")}
              type="text"
              {...register("supervisor", {
                required: "This field is required",
              })}
              error={errors?.supervisor}
            />

            <Controller
              control={control}
              name="hire_date"
              rules={{ required: "Hire date is required" }}
              render={({ field: { onChange, value } }) => (
                <PrimaryDatePicker
                  label={t("Hire Date")}
                  startDate={value}
                  {...register("hire_date")}
                  setStartDate={(date: string) => onChange(date)}
                  error={errors.hire_date}
                />
              )}
            />
            <Input
              label={t("Length Of Probation")}
              placeholder={t("Choose length of probation")}
              type="number"
              {...register("length_of_probation")}
              error={errors?.length_of_probation}
            />
            <div>
              <div className="flex items-center gap-2">
              <Label>{t("Contract Ends")}</Label>
                <div className="relative" onMouseLeave={onMouseLeave}>
                  <Button onMouseEnter={onMouseEnter}>
                    <Icon
                      iconType={IconType.ALERT_ICON}
                      className="absolute right-[-10] bottom-[5px]"
                      color={"var(--theme)"}
                    />
                  </Button>
                  <Tooltip
                    isOpen={isHovered}
                    className="absolute right-0 w-[250px] h-auto bg-white p-[10px] rounded-[25px] shadow-custom  top-[30px] left-[10px] !z-[99999]"
                    content={
                      <Fragment>
                        <ul className="list-disc text-[14px]  ml-4 font-inter-regular">
                          <li>
                            {t(
                              "Add only if its a fixed-term employment contract"
                            )}.
                          </li>
                        </ul>
                      </Fragment>
                    }
                  />
                </div>
              </div>
              <Controller
                control={control}
                name="contract_ends"
                render={({ field: { onChange, value } }) => (
                  <PrimaryDatePicker
                    // label={t("Contract Ends")}
                    startDate={value}
                    {...register("contract_ends")}
                    setStartDate={(date: string) => onChange(date)}
                    error={errors.contract_ends}
                  />
                )}
              />
            </div>

            <div>
              <Label>{t("Vacation Days")}</Label>
              <div className="w-full">
                <Controller
                  control={control}
                  name="vacation_days"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CounterControl
                      count={value}
                      className="!mt-0"
                      classes={{
                        container: "w-full bg-white !border-inputborder",
                      }}
                      isInput
                      onChange={(e: any) => {
                        onChange(parseFloat(e.target.value));
                      }}
                      onIncrement={() => onChange(value + 1)}
                      onDecrement={() =>
                        onChange(value >= 1 ? value - 1 : value)
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </InputsFolder>
        {/* <InputsFolder title={t('On-Boarding')}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">

                        <div className="relative">
                            <Label text={t('Assign Template')} />
                            <Controller
                                name="on_boarding_template_id"
                                control={control}
                                render={({ field }) => (
                                    <CreatableSelect<any, false>
                                        {...field}
                                        placeholder={t('Choose template')}
                                        options={[
                                            // { label: t('template 1'), value: 'template 1' },
                                            // { label: t('template 2'), value: 'template 2' },
                                        ]}
                                        value={
                                            field?.value
                                                ? {
                                                    value: field?.value,
                                                    label: t(field?.value),
                                                }
                                                : null
                                        }
                                        className="cursor-pointer font-inter-regular react-select-container"
                                        classNamePrefix="react-select"
                                        onChange={(newValue: any, actionMeta: any) => {
                                            if (
                                                actionMeta.action === "select-option" &&
                                                newValue?.value
                                            ) {
                                                field.onChange(newValue.value);
                                            }
                                        }}
                                        styles={customStyles}
                                        isValidNewOption={() => false}
                                        menuPosition={"fixed"}
                                        menuPortalTarget={document.body}
                                    />
                                )}
                            />
                            {errors.on_boarding_template_id?.message && (
                                <>
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        <>{errors.on_boarding_template_id?.message}</>
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </InputsFolder> */}
      </div>
      <div className="flex flex-row justify-end gap-5 pt-10">
        <IconButton className="" type="submit">
          <div className="flex items-center gap-2">
            {t(`Next Step`)}
            <Icon iconType={IconType.RIGHT_ARROW_ICON} />
          </div>
        </IconButton>
      </div>
    </Form>
  );
};

export default Step1EmployeeInfo;
