import { getAppointmentSource } from "api/Appointment";
import { useQuery } from "react-query";

export const useAppointmentSource = (
  params: any,
) => {
  return useQuery<Array<any> | any>(
    ["appointmentSource"],
    () => getAppointmentSource(params),
    {
      onError(error) {
        console.error(error)
      },
    }
  );
};
