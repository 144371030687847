import IconButton from 'components/atoms/button/IconButton'
import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import EmptyModule from 'components/molecules/empty-module/EmptyModule'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SalariesTable from './salaries-table/SalariesTable'
import Input from 'components/atoms/input/Input'
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown'
import ConfigureTableDropdown from '../leads/dropdowns/ConfigureTableDropdown'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper'
import { useSalaries } from 'lib/hooks/queries/Finances/useSalaries'
import AddNewSalaryModule from './add-new-salary-module/AddNewSalaryModule'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import { usePermissions } from 'lib/hooks/shared/usePermissions'


const FinanceSalariesView = () => {

    const { t } = useTranslation()

    const hasPermissionToCreateSalaries = usePermissions(['salaries_create']);

    const TableHeaders = [
        {
            id: 1,
            Header: `${t('Creation Date')}`,
            accessor: (row: any) =>
                row?.created_at,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {new Date(row.original?.created_at).toLocaleDateString('de')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: `${t('Name')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.name || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: `${t('Assigned Workers')}`,
            accessor: (row: any) =>
                row?.name,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer flex">
                    {row.original?.assigned_users?.length > 0 ? (
                        row.original?.assigned_users.map((user: any, index: number) => (
                            index < 5 && (
                                user?.users?.user_information?.profil_picture !== null ? (
                                    <img src={'https://devmainhub.insusales.com/api/profile-picture/' + user?.users?.user_information?.profil_picture} alt="" className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full`} key={index} />
                                ) : (
                                    <img src={'https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg'} alt="" className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full flex-none`} key={index} />

                                )
                            )
                        ))

                    ) : (
                        t('Not available')
                    )}

                    {(row.original?.assigned_users?.length > 5) && (
                        <div className="w-[35px] h-[35px] rounded-full -ml-3 bg-[--theme-bg-opacity] border-2 border-white text-[--theme] grid place-items-center font-inter-medium text-xs">
                            {row.original?.assigned_users.length - 5}
                        </div>
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: `${t('Performance Target')}`,
            accessor: (row: any) =>
                row?.performance_target,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.performance_target || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: `${t('Commission')}`,
            accessor: (row: any) =>
                row?.commission,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.commission || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: `${t('Bonus')}`,
            accessor: (row: any) =>
                row?.bonus,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.bonus || t('Not available')}
                </div>
            ),
            isChecked: true
        },
        {
            Header: <span className="sr-only"> {t('Actions')} </span>,
            accessor: "actions",
            id: 7,
            Cell: ({ row: { original } }: any) => (
               <div
                     className="cursor-pointer"
                     onClick={() => {
                     }}
                   >
                     <Icon iconType={IconType.OPTIONS_ICON} />
                   </div>
            ),
            isChecked: true
        }
    ];

    const [CardsOrder, setCardsOrder] = useState(TableHeaders)

    const [configureTableOpen, setConfigureTableOpen] = useState(false)

    const [SearchValue, setSearchValue] = useState('')

    const { data, isLoading } = useSalaries(SearchValue)

    const [openUploadModal, setOpenUploadModal] = useState(false)
    const [ModalCurrentStep, setModalCurrentStep] = useState(1)


    return (
        <div>
            <Headline title='Salaries' />
            <DashboardBoxLayout>
                <div className="flex items-center justify-between mb-5">
                    <DashboardBoxTitle title={`${t('Salarie Modules')}  ${data?.length ? `(${data.length})` : ''}`} />
                    <ConditionalRender condition={hasPermissionToCreateSalaries}>
                    <IconButton icon={<Icon iconType={IconType.PLUS_ICON} />} onClick={() => { setOpenUploadModal(true) }}>
                        {t('New Salary Module')}
                    </IconButton>
                    </ConditionalRender>
                </div>
                <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
                    <div className="flex-1">
                        <Input
                            icon={IconType.SEARCH_ICON}
                            className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                            classes={{ icon: "left-4 !top-[30%]" }}
                            placeholder={t("Search")}

                            onChange={(e) =>
                                setSearchValue(e.target.value)
                            }
                        />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="flex-none">
                            <div className={`relative`}>
                                <FilterBorderedWrapper
                                    className="cursor-pointer h-[48px] w-[48px] "
                                    onClick={() => {
                                        setConfigureTableOpen(!configureTableOpen);
                                    }}
                                >
                                    <Icon iconType={IconType.FILTER_BARS_ICON} />
                                </FilterBorderedWrapper>
                                <ConditionalRender condition={configureTableOpen}>
                                    <DynamicDropdown
                                        setIsOpen={setConfigureTableOpen}
                                        showCloseButton={true}
                                        title={t('Configure Table')}
                                    >
                                        <ConfigureTableDropdown
                                            setCardsOrder={setCardsOrder}
                                            cardsOrder={CardsOrder}
                                        />
                                    </DynamicDropdown>
                                </ConditionalRender>
                            </div>
                        </div>
                    </div>
                </div>
                <ConditionalRender condition={isLoading}>
                    <div className="relative py-10">
                        <LoadingScreen />
                    </div>
                </ConditionalRender>
                <ConditionalRender condition={data?.length === 0}>
                    <EmptyModule title='Salaries modules' />
                </ConditionalRender>
                <ConditionalRender condition={data !== undefined && data?.length > 0}>
                    <SalariesTable
                        data={data || []}
                        dataCols={CardsOrder}
                    />
                </ConditionalRender>

            </DashboardBoxLayout>
            <AddNewSalaryModule
                clearData={true}
                setHocCurrentStep={setModalCurrentStep}
                hocCurrentStep={ModalCurrentStep}
                onConfirm={() => setOpenUploadModal(false)}
                setFormCurrentStep={setModalCurrentStep}
                currentStep={ModalCurrentStep}
                hocisOpen={openUploadModal}
                hocToggleModal={() => setOpenUploadModal(false)}
                hocTitle={"Add New Salary Module"}
                hocClasses={{
                    modalClassName: "w-full md:max-w-[1000px]",
                    titleClassName: "ml-[35px]"
                }}
            />
        </div>
    )
}

export default FinanceSalariesView