import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getResources } from "api/Documents";
import { usePermissions } from "lib/hooks/shared/usePermissions";


interface FiltersProps {
    search?: string
}
export const useResources = (
    filters?: FiltersProps
) => {

    const hasPermissionToViewResources = usePermissions(['manage_resources_view']);

    const { data, isLoading } = useQuery<Array<any>>(
        ['resources', filters?.search],
        () => {
            if(hasPermissionToViewResources) {
                return getResources({
                    search: filters?.search
                });
            } else {
                return undefined as any
            }
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
