import { useForm } from "react-hook-form";
import * as YUP from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const StepSchemas = [
  YUP.object().shape({
    name: YUP.string()
      .required("Name is required")
      .min(3, "Name is too short"),
    last_name: YUP.string()
      .required("Lastname is required")
      .min(3, "Lastname is too short"),
    code: YUP.string().min(1),
    phone: YUP.number()
      .typeError("Phone number is required")
      .required("Phone number is required")
      .min(6, "Phone number is too short"),
    email: YUP.string()
      .required("Email is required")
      .email("Email is not valid")
      .min(6, "Email should be more than 6 letters"),
    password: YUP.string()
      .required("Password is required")
      .min(8, "Password should be more than 8 letters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Min 8 chars, 1 uppercase, 1 lowercase, 1 number, 1 special char.r"
      ),
  }),
  YUP.object().shape({
    company_name: YUP.string()
      .required("Company name is required")
      .min(3, "Company name is too short"),
    ui_number: YUP.string().required("VAT number is required"),
    company_website: YUP.string().optional(),
    company_email: YUP.string()
      .required("A company email is required")
      .email("Email is not valid")
      .min(6, "Email should be more than 6 letters"),
    
    company_address: YUP.string()
      .required("Company address is required")
      .min(3, "Company address is too short"),
  }),
  YUP.object().shape({
    company_logo: YUP.mixed()
      .required("Company logo is required")
      .test("name", "Company logo is required", (value: any) => {
        return value[0] && value[0].name !== "";
      }),
    // theme_color: YUP.string()
    //   .required("Themenfarbe ist erforderlich")
    //   .min(3, "Themenfarbe ist zu kurz")
    //   .optional(),
    business_direction: YUP.string()
      .required("Business direction is required")
      .min(1, "Business direction is too short"),
  }),
  YUP.object().shape({
    workers: YUP.array().of(
      YUP.object().shape({
        name: YUP.string().required("Name is required"),
        position: YUP.string().required("Position is required"),
        email: YUP.string().email("Email is optional").optional(),
        password: YUP.string().nullable(),
      })
    ),
  }),
  YUP.object().shape({
    modules: YUP.array()
      .min(1, "Please select at least one module")
      .required("Please select at least one module"),
  }),
];
export function useSignupForm(step: number, defaultValues = {}) {
  const schema = StepSchemas[step];
  return useForm<any>({
    mode: "onSubmit",
    resolver: yupResolver(schema as YUP.AnyObjectSchema),
    defaultValues: defaultValues,
  });
}
export type useStepForm = ReturnType<typeof useSignupForm>;
